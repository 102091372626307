import React, { useEffect, useMemo, useState } from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import useTranslate from "commons/hooks/useTranslate";
import PageCard from "commons/components/PageCard";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";
import Stack from "commons/components/Stack";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
  IconButton,
} from "@material-ui/core";
import {
  DurationFilter,
  ReportsToolbar,
} from "commons/components/ReportManager";
import { prop } from "ramda";
import { sumField } from "commons/helpers/utils";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import dayjs from "dayjs";

const columns = [
  {
    name: "account",
    type: "text",
  },
  {
    name: "debit",
    type: "money",
  },
  {
    name: "credit",
    type: "money",
  },
  {
    name: "difference",
    type: "money",
  },
];

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

const getChildren = (account_id, accounts) => {
  const children = [
    ...accounts
      .filter((account) => account.parent_account_id === account_id)
      .map(prop("id")),
  ];
  if (children.length === 0) {
    return [];
  } else {
    return [
      ...children,
      ...children.flatMap((child) => getChildren(child, accounts)),
    ];
  }
};

export function useAccountsReports(duration) {
  const [records, send, error, isLoading] = useResourcesByQuery(
    "accounts-balance",
    false
  );

  useEffect(() => {
    send("SET_QUERY", {
      query: {
        created: {
          $gte: duration.start,
          $lte: duration.end,
        },
      },
    });
  }, [send, duration]);

  const [accounts] = useResourcesByQuery("accounts", true);
  const accountsTree = useMemo(() => {
    return accounts
      .map((account) => ({
        ...account,
        children: getChildren(account.id, accounts),
      }))
      .map((account) => {
        const account_records = records.filter(
          (rec) =>
            rec.account_id === account.id ||
            account.children.includes(rec.account_id)
        );
        const debit_total = sumField("debit")(account_records);
        const credit_total = sumField("credit")(account_records);
        return {
          ...account,
          debit_total,
          credit_total,
          difference: debit_total - credit_total,
        };
      });
  }, [accounts, records]);

  return { accounts, records, error, isLoading, accountsTree };
}

export default function AccountsBalance() {
  const [duration, setDuration] = useState({
    start: dayjs().startOf("YEAR"),
    end: dayjs().endOf("YEAR"),
  });
  const { t } = useTranslate();
  const classes = useStyles();
  const { records, error, isLoading, accountsTree } = useAccountsReports(
    duration
  );

  const debit_total = useMemo(() => {
    return sumField("debit")(records);
  }, [records]);

  const credit_total = useMemo(() => {
    return sumField("credit")(records);
  }, [records]);

  const difference = debit_total - credit_total;

  return (
    <PageCard>
      <LoadingIndicator show={isLoading} />
      <ErrorAlert error={error} />
      <Stack>
        <ReportsToolbar
          title="accountsBalance"
          columns={columns}
          records={records}
        />
        <DurationFilter
          duration={duration}
          onChange={setDuration}
          initialType="YEAR"
        />
        <TableContainer>
          <Table>
            <TableHead className={classes.highlight}>
              <TableRow>
                <TableCell />
                {columns.map((col) => (
                  <TableCell key={col.name}>
                    {t(col.label || col.name)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {records.length > 0 && (
              <tfoot className={classes.highlight}>
                <tr>
                  <td></td>
                  <td></td>
                  <MyTableCell value={viewCol(debit_total, "money")} />
                  <MyTableCell value={viewCol(credit_total, "money")} />
                  <MyTableCell value={viewCol(difference, "difference")} />
                </tr>
              </tfoot>
            )}
            <TableBody>
              {/* {records.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1}>
                    {t("noResources")}
                  </TableCell>
                </TableRow>
              )} */}
              <MyTableRowGroup parent={null} accounts={accountsTree} />
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </PageCard>
  );
}

export const viewCol = (value, type) => {
  switch (type) {
    case "money":
      return !!value ? Math.abs(Math.floor(value) / 100) : 0;
    case "balance":
      return !!value ? Math.floor(value) / 100 : 0;
    case "difference":
      return !!value
        ? Math.floor(value) / 100 > 0
          ? Math.floor(value) / 100
          : `(${Math.abs(Math.floor(value) / 100)})`
        : 0;
    default:
      return value;
  }
};

function MyTableRowGroup({ parent, accounts, depth = 1 }) {
  const myChildren = accounts.filter((acc) => acc.parent_account_id === parent);

  return myChildren.map((acc) => (
    <MyTableRow key={acc.id} acc={acc} accounts={accounts} depth={depth} />
  ));
}

function MyTableRow({ acc, accounts, depth }) {
  const [show, setShow] = useState(false);
  const myChildren = accounts.filter(
    (curr) => curr.parent_account_id === acc.id
  );

  return (
    <React.Fragment>
      <TableRow hover key={acc.id}>
        <TableCell width={40}>
          {myChildren.length > 0 && (
            <IconButton size="small" onClick={() => setShow(!show)}>
              {show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <MyTableCell
          value={Array(depth).join("----") + " (" + acc.code + ") " + acc.name}
        />
        {!show ? (
          <>
            <MyTableCell value={viewCol(acc.debit_total, "money")} />
            <MyTableCell value={viewCol(acc.credit_total, "money")} />
            <MyTableCell value={viewCol(acc.difference, "difference")} />
          </>
        ) : (
          <TableCell colSpan="3" />
        )}
      </TableRow>
      {show && (
        <MyTableRowGroup
          parent={acc.id}
          accounts={accounts}
          depth={depth + 1}
        />
      )}
    </React.Fragment>
  );
}

export function MyTableCell({ value }) {
  return (
    <TableCell>
      <Box display="block" maxWidth={200}>
        <Typography variant="body1" noWrap>
          {value}
        </Typography>
      </Box>
    </TableCell>
  );
}
