import { Box, Grid, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Add, Print, Book } from "@material-ui/icons";
import CardSection from "commons/components/CardSection";
import { FormNumberField } from "commons/components/FormNumberField";
import FormSelectField from "commons/components/FormSelectField";
import PageCard from "commons/components/PageCard";
import Stack from "commons/components/Stack";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import useTranslate from "commons/hooks/useTranslate";
import { adjust, assoc, mergeLeft } from "ramda";
import React, { useEffect, useRef, useState } from "react";
import JsBarcode from "jsbarcode";
import { useReactToPrint } from "react-to-print";
import FormSwitch from "commons/components/FormSwitch";
import FormTextField from "commons/components/FormTextField";

const getRecord = () => ({
  id: Date.now(),
  product_id: null,
  code: null,
  price: 0,
  name: "",
  count: 1,
});

export default function BarcodePrinting() {
  const { t } = useTranslate();
  const [options] = useResourcesByQuery("products", true);
  const [products, setProducts] = useState([getRecord()]);
  const [template, setTemplate] = useState("custom");
  // const [width, setWidth] = useState(80);
  // const [height, setHeight] = useState(40);
  // const [barcodeWidth, setBarcodeWidth] = useState(2);
  // const [barcodeHeight, setBarcodeHeight] = useState(100);
  // const [fontSize, setFontSize] = useState(20);
  const baseSettings = () =>
    JSON.parse(window.localStorage.getItem("barcodeSettings")) || {
      title: "",
      width: 80,
      height: 40,
      barcodeWidth: 2,
      barcodeHeight: 100,
      fontSize: 20,
      showCode: true,
      showName: false,
      showPrice: false,
      marginTop: 1,
      marginBottom: 1,
      marginLeft: 1,
      marginRight: 1,
    };
  const [settings, setSettings] = useState(baseSettings);
  const printContainerRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printContainerRef.current,
  });

  const updateProductId = (index) => (id) => {
    const prod = options.find((p) => p.id === id);
    if (prod) {
      setProducts(
        adjust(
          index,
          mergeLeft({
            product_id: id,
            code: prod.code,
            name: prod.name,
            price: prod.sale_price,
          }),
          products
        )
      );
    }
  };

  const updateProductCount = (index) => (count) => {
    setProducts(adjust(index, assoc("count", Number(count)), products));
  };

  const changeTemplate = (template) => {
    setTemplate(template);
    if (template === "100x150") {
      updateSettings("width")(100);
      updateSettings("height")(100);
    }
    if (template === "80x40") {
      updateSettings("width")(80);
      updateSettings("height")(40);
    }
  };

  const updateSettings = (field) => (value) => {
    setSettings((old) => ({ ...old, [field]: value }));
  };

  const saveSettings = () =>
    window.localStorage.setItem("barcodeSettings", JSON.stringify(settings));

  return (
    <PageCard>
      <Stack>
        <Toolbar disableGutters>
          <Typography style={{ flex: "1 1 100%" }} variant="h4">
            {t("barcode-printing")}
          </Typography>
          <IconButton onClick={handlePrint}>
            <Print />
          </IconButton>
          <IconButton onClick={saveSettings}>
            <Book />
          </IconButton>
        </Toolbar>
        <Grid container spacing={2}>
          <Grid item xs sm={6}>
            <CardSection>
              <h2 style={{ marginTop: 0 }}>{t("products")}</h2>
              {products.map((product, i) => (
                <Grid container spacing={2} key={product.id}>
                  <FormSelectField
                    grid={3}
                    label="code"
                    value={product.product_id}
                    options={options}
                    optionLabel="code"
                    onChange={updateProductId(i)}
                  />
                  <FormSelectField
                    grid={6}
                    label="name"
                    value={product.product_id}
                    options={options}
                    onChange={updateProductId(i)}
                  />
                  <FormNumberField
                    grid={3}
                    label="count"
                    value={product.count}
                    options={options}
                    onChange={updateProductCount(i)}
                  />
                </Grid>
              ))}
              <IconButton
                onClick={() => setProducts((old) => [...old, getRecord()])}
              >
                <Add />
              </IconButton>
            </CardSection>
          </Grid>
          <Grid item xs sm={6}>
            <CardSection>
              <Stack>
                <h2 style={{ marginTop: 0 }}>{t("settings")}</h2>
                <Grid container spacing={2}>
                  <FormSelectField
                    grid={4}
                    label="size"
                    value={template}
                    options={[
                      { id: "100x150", name: "100mmx150mm" },
                      { id: "80x40", name: "80mmx40mm" },
                      { id: "custom", name: t("CUSTOM") },
                    ]}
                    onChange={changeTemplate}
                  />
                  <FormNumberField
                    grid={2}
                    label="width"
                    value={settings.width}
                    onChange={updateSettings("width")}
                    disabled={template !== "custom"}
                  />
                  <FormNumberField
                    grid={2}
                    label="height"
                    value={settings.height}
                    onChange={updateSettings("height")}
                    disabled={template !== "custom"}
                  />
                  <FormNumberField
                    grid={4}
                    label="fontSize"
                    value={settings.fontSize}
                    onChange={updateSettings("fontSize")}
                  />
                  <FormNumberField
                    grid={2}
                    label="width"
                    value={settings.barcodeWidth}
                    onChange={updateSettings("barcodeWidth")}
                  />
                  <FormNumberField
                    grid={2}
                    label="height"
                    value={settings.barcodeHeight}
                    onChange={updateSettings("barcodeHeight")}
                  />
                  <FormNumberField
                    grid={2}
                    label="top"
                    value={settings.marginTop}
                    onChange={updateSettings("marginTop")}
                  />
                  <FormNumberField
                    grid={2}
                    label="bottom"
                    value={settings.marginBottom}
                    onChange={updateSettings("marginBottom")}
                  />
                  <FormNumberField
                    grid={2}
                    label="left"
                    value={settings.marginLeft}
                    onChange={updateSettings("marginLeft")}
                  />
                  <FormNumberField
                    grid={2}
                    label="right"
                    value={settings.marginRight}
                    onChange={updateSettings("marginRight")}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <FormTextField
                    grid={4}
                    label="title"
                    value={settings.title}
                    onChange={updateSettings("title")}
                  />
                  <Grid item sm={8}>
                    <Grid container spacing={2}>
                      <FormSwitch
                        label="name"
                        grid={4}
                        value={settings.showName}
                        onChange={updateSettings("showName")}
                      />
                      <FormSwitch
                        label="code"
                        grid={4}
                        value={settings.showCode}
                        onChange={updateSettings("showCode")}
                      />
                      <FormSwitch
                        label="price"
                        grid={4}
                        value={settings.showPrice}
                        onChange={updateSettings("showPrice")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box display="flex" flexWrap="wrap" ref={printContainerRef}>
                  {products
                    .filter((prod) => prod.code !== null)
                    .flatMap((prod) =>
                      Array(prod.count)
                        .fill()
                        .map((elem, i) => (
                          <Box
                            key={`${prod.id}-${i}`}
                            width={`${settings.width}mm`}
                            height={`${settings.height}mm`}
                            bgcolor="white"
                            marginTop={`${settings.marginTop}mm`}
                            marginBottom={`${settings.marginBottom}mm`}
                            marginLeft={`${settings.marginLeft}mm`}
                            marginRight={`${settings.marginRight}mm`}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <p
                              style={{
                                fontSize: `${settings.fontSize}px`,
                                margin: 0,
                              }}
                            >
                              <strong>
                                {settings.title && settings.title}
                              </strong>
                            </p>
                            <Box
                              display="flex"
                              justifyContent="center"
                              width="100%"
                            >
                              <p
                                style={{
                                  fontSize: `${settings.fontSize}px`,
                                  margin: 0,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {settings.showName && prod.name}
                              </p>
                              <p
                                style={{
                                  fontSize: `${settings.fontSize}px`,
                                  margin: 0,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {settings.showPrice &&
                                  ` - ${(prod.price / 100).toFixed(2)}`}
                              </p>
                            </Box>
                            <BarcodeViewer
                              width={settings.barcodeWidth}
                              height={settings.barcodeHeight}
                              fontSize={settings.fontSize}
                              value={prod.code}
                              showCode={settings.showCode}
                            />
                          </Box>
                        ))
                    )}
                </Box>
              </Stack>
            </CardSection>
          </Grid>
        </Grid>
      </Stack>
    </PageCard>
  );
}

function BarcodeViewer({ value, showCode, height, width, fontSize }) {
  const ref = useRef(null);

  useEffect(() => {
    const validValue =
      value.length >= 12 ? value : "20" + value.padStart(5, "0") + "00000";
    JsBarcode(ref.current, validValue, {
      format: "EAN13",
      displayValue: showCode,
      width: width,
      height: height,
      fontSize: fontSize,
      flat: true,
    });
  }, [ref, value, width, height, fontSize, showCode]);

  return <svg ref={ref}></svg>;
}
