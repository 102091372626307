import React, { useState, useRef, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Grid,
  Box,
  Tooltip,
} from "@material-ui/core";
import { Print } from "@material-ui/icons";
import useTranslate from "commons/hooks/useTranslate";
import usePrintTemplates from "commons/hooks/usePrintTemplates";
import usePermissions from "commons/hooks/usePermissions";
import { ComponentsMap } from "commons/pages/print-templates/BuilderComponents";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";
import api from "commons/helpers/api";

export function usePrintManager() {
  const [active, setActive] = useState(null);
  const { templates } = usePrintTemplates();
  const onPrintRequest = (id) => {
    const template = templates.find((template) => template.id === id);
    if (template) {
      setActive(template);
    }
  };
  const onPrintCompleted = () => {
    if (active !== null) {
      api
        .service("print-templates")
        .patch(active.id, { counter: Number(active.counter) + 1 });
      setActive(null);
    }
  };

  return { active, onPrintRequest, onPrintCompleted };
}

const getMargins = (active) => {
  if (!active) return {};
  const marginTop = active.margin_top || 0;
  const marginBottom = active.margin_bottom || 0;
  const marginLeft = active.margin_left || 0;
  const marginRight = active.margin_right || 0;
  return {
    margin: `${marginTop}mm ${marginRight}mm  ${marginBottom}mm  ${marginLeft}mm `,
  };
};
const getDirection = (active, language) => {
  if (!active) return language === "ar" ? "rtl" : "ltr";
  return active.direction;
};

export function PrintTemplate({
  active = null,
  onPrintCompleted,
  children,
  content,
}) {
  const { language } = useTranslate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onPrintCompleted,
    // print: console.log,
  });

  useEffect(() => {
    if (active !== null) {
      handlePrint();
    }
  }, [active, handlePrint]);
  // position: absolute; top: 0px; left: 0px;background: #fff;width: 100%;height: 100%;z-index: 999999

  return (
    <div
      style={getMargins(active)}
      ref={componentRef}
      dir={getDirection(active, language)}
    >
      {active && active.header && (
        <div className="print-only">
          <PrintSectionViewer
            template={JSON.parse(active.header)}
            counter={active.counter}
          />
        </div>
      )}
      {active && content ? (
        <div
          style={{
            fontSize: active.content_font_size + "px" || "inherit",
            lineHeight: active.content_line_height || "inherit",
          }}
        >
          {content(active.language_id)}
        </div>
      ) : (
        children
      )}
      {active && active.footer && (
        <div className="print-only">
          <PrintSectionViewer
            template={JSON.parse(active.footer)}
            counter={active.counter}
          />
        </div>
      )}
    </div>
  );
}

export function PrintButton({
  onPrintRequest,
  filterFunc = (item) => item.general === true,
  renderFunc = (handleMenu) => (
    <IconButton onClick={handleMenu}>
      <Print />
    </IconButton>
  ),
}) {
  const { t } = useTranslate();
  const { templates } = usePrintTemplates();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t("print")}>{renderFunc(handleMenu)}</Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        {templates.filter(filterFunc).map((template) => (
          <MenuItem
            key={template.id}
            onClick={() => onPrintRequest(template.id)}
          >
            <ListItemText primary={template.name} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function PrintSectionViewer({ template = [], counter = 0 }) {
  const { user } = usePermissions();
  const getValue = (item) => {
    if (item.type === "date") return dayjs().format("YYYY-MM-DD HH:mm");
    if (item.type === "username") return user.username;
    if (item.type === "counter") return Number(counter) + 1;
    return null;
  };

  return (
    <Grid container alignItems="flex-start" spacing={1}>
      {template.map((item) => {
        const CurrentComponent = ComponentsMap[item.type];
        return (
          <Grid item xs={parseInt(item.size)} key={item.id}>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent={item.align}
              textAlign={item.align}
              bgcolor={item.bgColor || "none"}
              color={item.fontColor || "inherit"}
              p={parseInt(item.padding) || 0}
            >
              <CurrentComponent item={item} value={getValue(item)} />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
