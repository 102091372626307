import React from "react";
import ReportManager, {
  totalsColumns,
  totalsTransformer,
} from "commons/components/ReportManager";

const filters = [
  { type: "filter", name: "facilities", key: "facility_id" },
  {
    type: "filter",
    name: "users",
    key: "user_id",
    extras: { optionLabel: "username" },
  },
  { type: "filter", name: "customers", key: "customer_id" },
];

const columns = [
  {
    name: "user",
    type: "text",
  },
  ...totalsColumns,
  {
    name: "profit",
    type: "money",
  },
];

const byUserID = (record) => record.user_id + "";

export default function SalesByUser() {
  return (
    <ReportManager
      url="sales"
      title="salesByUser"
      filterByDate
      filters={filters}
      columns={columns}
      totals={[
        "number_of_operations",
        "subtotal",
        "discount",
        "tax",
        "total",
        "cost",
        "profit",
      ]}
      recordsFunc={totalsTransformer(byUserID, "user_id")}
    />
  );
}
