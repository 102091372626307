import React from "react";
import ReportManager from "commons/components/ReportManager";
import { columns } from "commons/pages/purchases/List";

const filters = [
  { type: "select", name: "facilities", key: "facility_id" },
  {
    type: "select",
    name: "users",
    key: "user_id",
    extras: { optionLabel: "username" },
  },
  { type: "select", name: "suppliers", key: "supplier_id" },
];

export default function PurchasesByOperation() {
  return (
    <ReportManager
      url="purchases"
      title="purchasesByOperation"
      columns={columns}
      filterByDate
      // hasChart
      filters={filters}
      totals={["subtotal", "discount", "tax", "total", "commission", "cost"]}
    />
  );
}
