import React from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { FormMoneyField } from "commons/components/FormMoneyField";
import ProductConnector from "commons/components/ProductConnector";
import useTranslate from "commons/hooks/useTranslate";
import { Grid, Typography } from "@material-ui/core";
import FormSelectField from "commons/components/FormSelectField";

const base = "policies";

export default function Single() {
  const { current, send, model, updateModel, id } = useControlledResourcePage(
    base
  );
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/policies/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "products",
            path: `/s/policies/${id}/products`,
            component: <Products id={id} />,
          },
          {
            name: "services",
            path: `/s/policies/${id}/services`,
            component: <Products id={id} services />,
          },
        ]}
      />
    </PageCard>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { t } = useTranslate();
  const { model, rules } = current.context;

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormSelectField
        grid={3}
        label="type"
        options={[
          { id: "SALES", name: t("SALES") },
          { id: "PURCHASES", name: t("PURCHASES") },
        ]}
        value={model.type}
        onChange={updateModel("type")}
        error={rules.type}
      />
      <FormMoneyField
        grid={3}
        label="credit_limit"
        value={model.credit_limit}
        onChange={updateModel("credit_limit")}
        error={rules.credit_limit}
      />
    </ResourceEditor>
  );
}

function Products({ id, ...props }) {
  const { t } = useTranslate();
  return (
    <ProductConnector
      id={id}
      {...props}
      relation="policy-product"
      relationKey="policy_id"
      model={{ price: 0, policy_id: id }}
      header={
        <Grid item sm={12}>
          <Typography variant="subtitle1">{t("price")}</Typography>
        </Grid>
      }
      rowRenderer={(record, onItemChange) => (
        <>
          <FormMoneyField
            size="small"
            value={record.price}
            onChange={onItemChange("price")}
          />
        </>
      )}
    />
  );
}
