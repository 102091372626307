import React from "react";
import { TextField, Typography } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "react-query";

import LoadingIndicator from "./LoadingIndicator";
import ErrorAlert from "./ErrorAlert";
import api from "commons/helpers/api";
import FilePreview from "./FilePreview";

const useStyles = makeStyles((theme) => ({
  fieldBackground: {
    padding: theme.spacing(1),
    background: theme.palette.background.default,
    textAlign: "center",
  },
  preview: {
    width: "128px",
    height: "128px",
    margin: "0 auto",
    border: "2px dashed rgba(0, 0, 0, 0.5)",
    marginBottom: theme.spacing(1),
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "auto",
  },
}));

function upload({ file }) {
  return api.service("uploads").create({ uri: file });
}

export default function FormFileField({
  size = 12,
  label,
  value,
  onChange,
  preview = false,
  ...props
}) {
  const { t } = useTranslate();
  const classes = useStyles();
  const { mutate, status, error } = useMutation(upload, {
    throwOnError: true,
  });

  const handleUpload = ({ target }) => {
    const fileReader = new FileReader();
    if (target.files) {
      fileReader.readAsDataURL(target.files[0]);
    }

    fileReader.addEventListener("load", async () => {
      const data = await mutate({ file: fileReader.result });
      onChange(data.id);
    });
  };

  return (
    <div className={classes.fieldBackground}>
      <LoadingIndicator show={status === "loading"} />
      <ErrorAlert error={error} />
      <Typography variant="h6">{t(label)}</Typography>
      {preview && (
        <div className={classes.preview}>
          {value && <FilePreview className={classes.image} source={value} />}
        </div>
      )}
      <TextField type="file" onChange={handleUpload} {...props} />
    </div>
  );
}
