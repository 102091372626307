import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import { assoc, compose, omit, __ } from "ramda";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import {
  AccountTree,
  Add,
  AddBox,
  FileCopy,
  History,
  ViewCompact,
  ViewList,
} from "@material-ui/icons";
import { DashboardShortcutPin } from "commons/components/DashboardShortcut";
import {
  AccessGroupButton,
  AuditManager,
} from "commons/components/ResourceToolbar";
import ResourceRemoveButton from "commons/components/ResourceRemoveButton";

export default function FrontdeskToolbar({
  sales = true,
  model,
  onChange,
  toggleShowCategories,
  toggleCompactView,
}) {
  const { t } = useTranslate();
  const history = useHistory();
  const title = sales ? "sales" : "purchases";
  const route = sales ? "sales" : "purchases";
  const [showLogs, setShowLogs] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuChoose = (resource) => {
    setSelected(resource);
    handleMenuClose();
    handleDialogOpen();
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };
  const onDuplicate = () => {
    history.push(
      `/s/${route}/create?model=${JSON.stringify(
        omit(["id", "reference"], model)
      )}`
    );
  };
  const goToList = () => history.push(`/s/${route}`);

  return (
    <>
      <Toolbar className="resource-toolbar" disableGutters>
        <Typography style={{ flex: "1 1 100%" }} variant="h4">
          {t(title)}
        </Typography>
        <Box className="no-print" display="flex" alignItems="center">
          <Tooltip title={t("new")}>
            <IconButton
              id="resource-new-btn"
              component={RouterLink}
              to={`/s/${route}/create`}
            >
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("duplicate")}>
            <IconButton id="resource-duplicate-btn" onClick={onDuplicate}>
              <FileCopy />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("list")}>
            <IconButton component={RouterLink} to={`/s/${route}`}>
              <ViewList />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("compactView")}>
            <IconButton onClick={toggleCompactView}>
              <ViewCompact />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("categories")}>
            <IconButton onClick={toggleShowCategories}>
              <AccountTree />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("fastAdd")}>
            <IconButton onClick={handleMenuClick}>
              <AddBox />
            </IconButton>
          </Tooltip>
          <DashboardShortcutPin title={title} />
          <Tooltip title={t("history")}>
            <IconButton
              onClick={() => {
                setShowLogs((old) => !old);
              }}
            >
              <History />
            </IconButton>
          </Tooltip>
          <ResourceRemoveButton
            url={route}
            resources={[model.id]}
            onResourcesRemoved={goToList}
          />
          <AccessGroupButton
            value={model.access_group_id}
            onChange={compose(onChange, assoc("access_group_id", __, model))}
          />
          {/* access group button */}
        </Box>
      </Toolbar>
      {showLogs && (
        <AuditManager
          id={model.id}
          url={route}
          onSelect={(log) => onChange(log.data)}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => handleMenuChoose(sales ? "customers" : "suppliers")}
        >
          {sales ? t("customer") : t("supplier")}
        </MenuItem>
        <MenuItem onClick={() => handleMenuChoose("products")}>
          {t("product")}
        </MenuItem>
        <MenuItem onClick={() => handleMenuChoose("discounts")}>
          {t("discount")}
        </MenuItem>
        <MenuItem onClick={() => handleMenuChoose("taxes")}>
          {t("tax")}
        </MenuItem>
        <MenuItem onClick={() => handleMenuChoose("employees")}>
          {t("employee")}
        </MenuItem>
        <MenuItem onClick={() => handleMenuChoose("contractors")}>
          {t("contractor")}
        </MenuItem>
      </Menu>
      <Dialog onClose={handleDialogClose} open={open} maxWidth="xl" fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
          {t(selected)}
        </DialogTitle>
        <DialogContent dividers>
          <DialogIframe base={selected} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export function DialogIframe({ base, path = "create" }) {
  const { t } = useTranslate();

  useEffect(() => {
    const iframeEl = document.getElementById("fastAdd-iframe");
    const handler = () => {
      iframeEl.contentDocument.body.classList.add("iframe-embeded");
    };
    iframeEl.addEventListener("load", handler);
    return () => {
      iframeEl.removeEventListener("load", handler);
    };
  }, []);

  return (
    <iframe
      id="fastAdd-iframe"
      className="frontdesk-iframe"
      style={{ margin: "1%" }}
      width="98%"
      height="96%"
      src={`/s/${base}/${path}`}
      title={t(base)}
    />
  );
}
