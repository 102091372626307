import React from "react";
import ReportManager from "commons/components/ReportManager";

const filters = [{ type: "filter", name: "accounts", key: "account_id" }];

const columns = [
  // {
  //   name: "transaction_id",
  //   type: "number",
  //   label: "transaction",
  // },
  {
    label: "transaction",
    name: "transaction_id",
    type: "link",
    field: "transaction_id",
    base: "transactions",
  },
  {
    name: "code",
    type: "text",
  },
  {
    name: "name",
    type: "text",
  },
  // {
  //   name: "approved",
  //   label: "date",
  //   type: "datetime",
  // },
  {
    name: "currency",
    type: "text",
  },
  {
    name: "rate",
    type: "money",
  },
  {
    name: "debit_in_currency",
    type: "money",
  },
  {
    name: "credit_in_currency",
    type: "money",
  },
  {
    name: "debit",
    type: "money",
  },
  {
    name: "credit",
    type: "money",
  },
  {
    name: "balance",
    type: "account_balance",
  },
];

const calcBalance = (records) => {
  return records.map((record) => ({
    ...record,
    balance: Number(record.debit) || 0 - Number(record.credit) || 0,
  }));
};

export default function TransactionsReport() {
  return (
    <ReportManager
      url="transaction-entries"
      title="transactions"
      filters={filters}
      columns={columns}
      recordsFunc={calcBalance}
      filterByDate
      dateField="transactions.created"
      totals={["debit", "credit", "balance"]}
      hideIndex
    />
  );
}
