import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

export const createStockLine = ({
  product_id,
  value,
  quantity,
  facility_id,
  fulfilled,
}) => {
  return {
    id: uuidv4(),
    facility_id,
    product_id,
    value,
    quantity,
    requested: dayjs(),
    fulfilled,
    serial: null,
    mfg_date: null,
    exp_date: null,
    total_value: value * quantity,
  };
};

export const groupStocks = (stocks = []) => {
  return stocks.reduce((acc, curr) => {
    const exists = acc.findIndex(
      (s) =>
        s.product_id === curr.product_id &&
        s.facility_id === curr.facility_id &&
        s.mfg_date === curr.mfg_date &&
        s.exp_date === curr.exp_date &&
        s.value === curr.value
    );
    if (exists !== -1) {
      const quantity = Number(curr.quantity) + Number(acc[exists].quantity);
      acc[exists] = {
        ...acc[exists],
        quantity,
        total_value: curr.value * quantity,
      };
      return [...acc];
    } else {
      return [...acc, curr];
    }
  }, []);
};

export const collapseStocks = (stocks = []) => {
  return stocks.reduce((acc, curr) => {
    const exists = acc.findIndex(
      (s) =>
        s.product_id === curr.product_id &&
        s.facility_id === curr.facility_id &&
        s.mfg_date === curr.mfg_date &&
        s.exp_date === curr.exp_date &&
        s.serial === curr.serial &&
        s.value === curr.value &&
        s.operation_type === curr.operation_type &&
        !Boolean(s.operation_line_id)
    );
    if (exists !== -1) {
      const quantity = Number(curr.quantity) + Number(acc[exists].quantity);
      acc[exists] = {
        ...acc[exists],
        quantity,
        total_value: curr.value * quantity,
      };
      return [...acc];
    } else {
      return [...acc, curr];
    }
  }, []);
};
