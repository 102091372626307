import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useTranslate from "commons/hooks/useTranslate";
import FormSelectField from "commons/components/FormSelectField";

const base = "languages";

export default function Single() {
  const { t } = useTranslate();
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormSelectField
        grid={6}
        label="direction"
        options={[
          { id: "rtl", name: t("rtl") },
          { id: "ltr", name: t("ltr") },
        ]}
        value={model.direction}
        onChange={updateModel("direction")}
        error={rules.direction}
      />
    </ResourceSinglePage>
  );
}
