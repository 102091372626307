import React from "react";
import ReportManager from "commons/components/ReportManager";
import useTranslate from "commons/hooks/useTranslate";

const columns = [
  {
    name: "name",
    type: "text",
  },
  {
    name: "operations_count",
    type: "number",
  },
  {
    name: "operations_avg",
    type: "money",
  },
  {
    name: "operations_min",
    type: "money",
  },
  {
    name: "operations_max",
    type: "money",
  },
  {
    name: "operations_total",
    type: "money",
    highlight: true,
  },
  {
    name: "payments_count",
    type: "number",
  },
  // {
  //   name: "payments_avg",
  //   type: "money",
  // },
  {
    name: "positive_payments",
    type: "money",
  },
  {
    name: "negative_payments",
    type: "money",
  },
  {
    name: "payments_total",
    type: "money",
    highlight: true,
  },
  {
    name: "balance",
    type: "balance",
    highlight: true,
  },
];

export default function CustomersReport() {
  const { t } = useTranslate();

  const filters = [
    {
      type: "filter",
      name: "customers",
      key: "customer_id",
    },
    {
      type: "filter",
      name: "currencies",
      key: "currency_id",
    },
    {
      type: "filter",
      name: "access-groups",
      key: "access_group_id",
    },
    {
      type: "select",
      name: "method",
      key: "method",
      options: [
        {
          id: "CASH",
          name: t("CASH"),
        },
        {
          id: "CREDIT_CARD",
          name: t("CREDIT_CARD"),
        },
        {
          id: "WIRE_TRANSFER",
          name: t("WIRE_TRANSFER"),
        },
        {
          id: "CHEQUE",
          name: t("CHEQUE"),
        },
      ],
    },
    {
      type: "select",
      name: "balance",
      key: "balance",
      multiple: false,
      options: [
        {
          id: "BALANCED",
          name: t("BALANCED"),
        },
        {
          id: "NEGATIVE",
          name: t("NEGATIVE_BALANCE"),
        },
        {
          id: "POSITIVE",
          name: t("POSITIVE_BALANCE"),
        },
      ],
    },
  ];

  return (
    <ReportManager
      url="balance"
      title="customersReport"
      columns={columns}
      filters={filters}
      totals={[
        "operations_count",
        "operations_total",
        "payments_count",
        "payments_total",
        "positive_payments",
        "negative_payments",
        "balance",
      ]}
      filterByDate
      initialQuery={{ type: "customers" }}
    />
  );
}
