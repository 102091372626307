import React from "react";
import ReportManager, {
  linesColumns,
  linesTransformer,
} from "commons/components/ReportManager";

const filters = [
  { type: "filter", name: "products", key: "product_id" },
  { type: "filter", name: "facilities", key: "sales.facility_id" },
  {
    type: "filter",
    name: "users",
    key: "sales.user_id",
    extras: { optionLabel: "username" },
  },
  { type: "filter", name: "customers", key: "sales.customer_id" },
];

const columns = [
  {
    name: "code",
    type: "text",
  },
  {
    name: "name",
    type: "text",
  },
  ...linesColumns,
  {
    name: "profit",
    type: "money",
  },
];

const byProductID = (record) => record.product_id + "";

export default function SalesByProduct() {
  return (
    <ReportManager
      filterByDate
      dateField="sales.date"
      url="sale-lines"
      title="salesByProduct"
      filters={filters}
      columns={columns}
      recordsFunc={linesTransformer(byProductID, "product_id")}
      totals={[
        "quantity",
        "returned",
        "actual",
        "subtotal",
        "discount",
        "total",
        "cost",
        "commission",
        "profit",
      ]}
    />
  );
}
