import React, { useEffect, useMemo, useState } from "react";
import CardSection from "commons/components/CardSection";
import useTranslate from "commons/hooks/useTranslate";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@material-ui/core";
import {
  ascend,
  compose,
  insert,
  prop,
  propEq,
  remove,
  sort,
  update,
} from "ramda";
import { CallSplit, Delete, InfoOutlined } from "@material-ui/icons";
import {
  FormDateField,
  FormDateTimeField,
} from "commons/components/FormDateField";
import { v4 as uuidv4 } from "uuid";
import FormTextField from "commons/components/FormTextField";
import FormSelectField from "commons/components/FormSelectField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import dayjs from "dayjs";

export default function StocksCard({
  model,
  onChange,
  products = [],
  facilities = [],
  // manualStockChange,
  printComponents = false,
  canDoAction,
}) {
  const { t } = useTranslate();
  const old = model.stocks || [];
  const lineStocks = model.lines
    ? model.lines.flatMap((line) => line.stocks)
    : [];
  const unfulfilled = lineStocks.filter((l) => !Boolean(l.fulfilled));
  const fulfilled = [...lineStocks.filter((l) => Boolean(l.fulfilled)), ...old];

  const [active, setActive] = useState("unfulfilled");

  const handleChange = (_, newValue) => {
    setActive(newValue);
  };

  return (
    <CardSection p={1}>
      <Tabs value={active} variant="fullWidth" onChange={handleChange}>
        <Tab label={t("stocks_fulfilled")} value="fulfilled" />
        <Tab label={t("stocks_unfulfilled")} value="unfulfilled" />
      </Tabs>
      {active === "fulfilled" && (
        <StocksCardPanel
          model={model}
          stocks={fulfilled}
          products={products}
          facilities={facilities}
          viewOnly
          onChange={onChange}
          // manualStockChange={manualStockChange}
          printComponents={printComponents}
          canDoAction={canDoAction}
        />
      )}
      {active === "unfulfilled" && (
        <>
          <StocksCardPanel
            model={model}
            stocks={unfulfilled}
            products={products}
            facilities={facilities}
            onChange={onChange}
            // manualStockChange={manualStockChange}
            printComponents={printComponents}
            canDoAction={canDoAction}
          />
        </>
      )}
    </CardSection>
  );
}

export function useStocksByFacility(
  stocks = [],
  products = [],
  facilities = []
) {
  const facilitiesNames = useMemo(
    () =>
      facilities.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {}),
    [facilities]
  );

  const productsNames = useMemo(
    () =>
      products.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.product_id]: { name: curr.name, code: curr.code },
        }),
        {}
      ),
    [products]
  );

  const stocksByFacility = useMemo(() => {
    const allStocks = stocks.filter(
      (stock) => stock.quantity && stock.quantity !== 0
    );
    return allStocks.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.facility_id]: [
          ...(acc[curr.facility_id] ? acc[curr.facility_id] : []),
          curr,
        ],
      }),
      {}
    );
  }, [stocks]);

  return {
    facilitiesNames,
    productsNames,
    stocksByFacility,
  };
}

function StocksCardPanel({
  stocks,
  products,
  facilities,
  model,
  onChange,
  canDoAction,
  // viewOnly = false,
  // manualStockChange = false,
  printComponents = false,
}) {
  const { t } = useTranslate();
  const [active, setActive] = useState(null);
  const { facilitiesNames, productsNames, stocksByFacility } =
    useStocksByFacility(stocks, products, facilities);

  const handleChange = (event, newValue) => {
    setActive(newValue);
  };

  useEffect(() => {
    const first = Object.keys(stocksByFacility)[0];
    if (first && active === null) {
      setActive(first);
    }
  }, [active, stocksByFacility]);

  const getStockLine = ({ product_id, id }) => {
    // Cannot rely on the product
    // const lineIndex = model.lines.findIndex(propEq("product_id", product_id));
    const lineIndex = model.lines.findIndex((l) => {
      const stockIndex = l.stocks.findIndex(propEq("id", id));
      return stockIndex !== -1;
    });
    if (lineIndex === -1) return null;
    const line = model.lines[lineIndex];
    const stockIndex = line.stocks.findIndex(propEq("id", id));
    if (stockIndex === -1) return null;
    return [line, lineIndex, stockIndex];
  };

  const stockUpdate = (lines) => {
    if (lines === null) return;
    onChange({ ...model, lines });
  };

  const splitStockLine = (result) => {
    if (result === null) return null;
    const [line, lineIndex, stockIndex] = result;
    const stock = line.stocks[stockIndex];
    const dir = stock.quantity < 0 ? -1 : 1;
    const updatedStocks = compose(
      insert(stockIndex + 1, {
        ...stock,
        id: uuidv4(),
        quantity: dir,
        total_value: stock.value * dir,
      }),
      update(stockIndex, {
        ...stock,
        quantity: stock.quantity - dir,
        total_value: stock.value * (stock.quantity - dir),
      })
    )(line.stocks);
    return update(lineIndex, { ...line, stocks: updatedStocks }, model.lines);
  };
  const onStockSplit = compose(stockUpdate, splitStockLine, getStockLine);

  const removeStockLine = (result) => {
    if (result === null) return null;
    const [line, lineIndex, stockIndex] = result;
    return update(
      lineIndex,
      { ...line, stocks: remove(stockIndex, 1, line.stocks) },
      model.lines
    );
  };
  const onStockRemove = compose(stockUpdate, removeStockLine, getStockLine);

  const onStockChangeData = (obj, field) => (value) => {
    const result = getStockLine(obj);
    if (result === null) return null;
    const [line, lineIndex, stockIndex] = result;
    const stock = line.stocks[stockIndex];
    const updatedStocks = update(
      stockIndex,
      { ...stock, [field]: value },
      line.stocks
    );
    stockUpdate(
      update(lineIndex, { ...line, stocks: updatedStocks }, model.lines)
    );
  };

  const onStockChangeValue = (obj) => (value) => {
    const result = getStockLine(obj);
    if (result === null) return null;
    const [line, lineIndex, stockIndex] = result;
    const stock = line.stocks[stockIndex];
    const updatedStocks = update(
      stockIndex,
      { ...stock, value, total_value: value * stock.quantity },
      line.stocks
    );
    stockUpdate(
      update(lineIndex, { ...line, stocks: updatedStocks }, model.lines)
    );
  };

  const hasUnfulfilled = stocks.some((s) => s.fulfilled === null);

  const fulfillAll = () => {
    const lines = model.lines.map((l) => ({
      ...l,
      stocks: l.stocks.map((s) => ({
        ...s,
        fulfilled: s.fulfilled === null ? dayjs() : s.fulfilled,
      })),
    }));
    onChange({ ...model, lines });
  };

  return (
    <div>
      {active && (
        <Tabs value={active} variant="fullWidth" onChange={handleChange}>
          {Object.keys(stocksByFacility).map((facility) => {
            return (
              <Tab
                key={facility}
                label={facilitiesNames[facility]}
                value={facility}
              />
            );
          })}
        </Tabs>
      )}
      {Object.keys(stocksByFacility).map((facility) => {
        const facilityStocks = stocksByFacility[facility];
        const byQty = ascend(prop("requested"));
        const sorted = sort(byQty, facilityStocks).filter(
          (rec) => Number(rec.quantity) !== 0
        );
        const filtered = sorted.filter((rec) => {
          if (
            !rec.operation_type ||
            ["sale", "purchase"].includes(rec.operation_type)
          )
            return true;
          return printComponents
            ? "componentOut" === rec.operation_type ||
                ("compositeOut" === rec.operation_type &&
                  Number(rec.quantity) > 0)
            : "compositeOut" === rec.operation_type;
        });
        return (
          <div key={facility} hidden={facility !== active}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>{t("code")}</TableCell>
                  <TableCell>{t("name")}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{t("fulfilled")}</TableCell>
                  {canDoAction("show-operation-cost") && (
                    <TableCell>{t("value")}</TableCell>
                  )}
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtered.map((line, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {t(line.quantity > 0 ? "enter" : "exit")}
                    </TableCell>
                    <TableCell padding="none">
                      {Math.abs(line.quantity) > 1 && (
                        <IconButton onClick={() => onStockSplit(line)}>
                          <CallSplit />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>
                      {productsNames[line.product_id]?.code}
                    </TableCell>
                    <TableCell>
                      {productsNames[line.product_id]?.name}
                    </TableCell>
                    <TableCell padding="none">
                      <StockLineData
                        line={line}
                        onStockChangeData={onStockChangeData}
                        products={products}
                        disabled={!canDoAction("edit-stock-line")}
                      />
                    </TableCell>
                    <TableCell padding="none">
                      <Box maxWidth={240}>
                        <FormDateTimeField
                          size="small"
                          value={line.fulfilled}
                          onChange={onStockChangeData(line, "fulfilled")}
                          onClear={() =>
                            onStockChangeData(line, "fulfilled")(null)
                          }
                          disabled={!canDoAction("unfulfill-stock-line")}
                        />
                      </Box>
                    </TableCell>
                    {canDoAction("show-operation-cost") && (
                      <TableCell padding="none">
                        <Box maxWidth={100} px={1}>
                          <FormMoneyField
                            size="small"
                            value={line.value}
                            onChange={onStockChangeValue(line)}
                            disabled={!canDoAction("edit-stock-line-price")}
                          />
                        </Box>
                      </TableCell>
                    )}
                    <TableCell>{Math.abs(line.quantity)}</TableCell>
                    <TableCell padding="none">
                      <IconButton
                        onClick={() => onStockRemove(line)}
                        disabled={!canDoAction("remove-stock-line")}
                      >
                        <Delete />
                      </IconButton>
                      {/* {!viewOnly && (
                      )}
                      {viewOnly && manualStockChange && (
                        <IconButton onClick={() => onStockReturn(line)}>
                          <KeyboardReturn />
                        </IconButton>
                      )} */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        );
      })}
      {hasUnfulfilled && (
        <Box mt={2}>
          <Button size="large" variant="outlined" onClick={fulfillAll}>
            {t("fulfill_all")}
          </Button>
        </Box>
      )}
    </div>
  );
}

function StockLineData({ products, line, onStockChangeData, disabled = true }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "stock-line-popover-" + line.id : undefined;
  const tags = useMemo(() => {
    const product = products.find(
      (prod) => prod.product_id === line.product_id
    );
    return product && product.storage_tags
      ? product.storage_tags.split("|")
      : [];
  }, [products, line]);

  const onTagsChange = (val) => {
    onStockChangeData(line, "tags")(val.join("|"));
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <InfoOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={1}>
          <Grid container spacing={1}>
            <FormSelectField
              multiple
              grid={3}
              label="storage_tags"
              value={
                typeof line.tags === "string" ? line.tags.split("|") : null
              }
              options={tags.map((tag) => ({ id: tag, name: tag }))}
              onChange={onTagsChange}
              disabled={disabled}
            />
            <FormTextField
              grid={3}
              label="serial"
              value={line.serial}
              onChange={onStockChangeData(line, "serial")}
              disabled={disabled}
            />
            <FormDateField
              grid={3}
              label="exp_date"
              value={line.exp_date}
              onChange={onStockChangeData(line, "exp_date")}
              disabled={disabled}
            />
            <FormDateField
              grid={3}
              label="mfg_date"
              value={line.mfg_date}
              onChange={onStockChangeData(line, "mfg_date")}
              disabled={disabled}
            />
          </Grid>
        </Box>
      </Popover>
    </>
  );
}
