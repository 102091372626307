import React, { useState, useEffect, useCallback } from "react";
import "commons/styles/App.css";
import "fontsource-roboto/latin.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createMuiTheme,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { CircularProgress } from "@material-ui/core";

import { compose, reduce } from "ramda";
import { TranslateContext } from "commons/helpers/contexts";
import api from "commons/helpers/api";
import Center from "commons/components/Center";
import ErrorAlert from "commons/components/ErrorAlert";

import Login from "commons/pages/base/Login";
import { arEG } from "commons/languages/areg";

const getCustomTheme = (settings = {}, lang) =>
  createMuiTheme(
    {
      direction: lang === "ar" ? "rtl" : "ltr",
      palette: {
        primary: {
          main: settings.theme_main_color || "#39b54a",
        },
        secondary: {
          main: settings.theme_secondary_color || "#536DFE",
        },
        background: {
          default: settings.theme_background_color || "#efefef",
          paper: settings.theme_surface_color || "#FFFFFF",
        },
        text: {
          disabled: "rgba(0, 0, 0, 0.87)",
        },
      },
      typography: {
        fontFamily: lang === "ar" ? "Tajawal" : "Roboto, Arial",
      },
      props: {
        MuiTextField: {
          fullWidth: true,
          variant: "filled",
        },
      },
    },
    lang === "ar" && arEG
  );

const jssRTL = create({ plugins: [...jssPreset().plugins, rtl()] });
const jss = create({ plugins: jssPreset().plugins });

export default function MainApp({
  arabic,
  english,
  Dashboard,
  showLangSelect = false,
}) {
  const initialLanguage = () => window.localStorage.getItem("language") || "ar";

  // const [status, setStatus] = useState("UNINITIALIZED");
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState(null);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(initialLanguage);
  const [theme, setTheme] = useState(getCustomTheme);

  const t = useCallback(
    (key) => {
      if (language === "ar" && arabic.hasOwnProperty(key)) {
        return arabic[key];
      }
      if (english.hasOwnProperty(key)) {
        return english[key];
      }
      return key;
    },
    [arabic, english, language]
  );

  const settingsTransformer = (acc, curr) => ({
    ...acc,
    [curr.key]: curr.value,
  });

  useEffect(() => {
    api
      .service("settings")
      .find()
      .then(compose(setSettings, reduce(settingsTransformer, {})))
      .catch((error) => {
        console.log(error);
        if (error.code && [500, 401].includes(error.code)) {
          window.location.reload();
        }
        setError(error);
      });
  }, [setSettings]);

  useEffect(() => {
    if (settings === null) return;
    setTheme(getCustomTheme(settings, language));
    document.title = settings.company_name;
    document.documentElement.setAttribute("lang", language);
    document.body.setAttribute("dir", language === "ar" ? "rtl" : "ltr");
    window.localStorage.setItem("language", language);
  }, [settings, language]);

  useEffect(() => {
    api
      .reAuthenticate()
      .then((user) => {
        setUser(user);
        setError(null);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setUser(false);
        // logout();
      });
  }, []);

  function login(username, password) {
    api
      .authenticate({
        strategy: "local",
        username,
        password,
      })
      .then((user) => {
        setUser(user);
        setError(null);
      })
      .catch((error) => {
        setUser(false);
        setError(error);
        console.log(error);
      });
  }

  function logout() {
    api.logout().then(() => setUser(false));
  }

  if (user === null || settings === null) {
    return (
      <Center>
        <CircularProgress size={80} />
      </Center>
    );
  }

  return (
    <TranslateContext.Provider value={{ language, setLanguage, t }}>
      <StylesProvider jss={language === "ar" ? jssRTL : jss}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorAlert error={error} />
          {!!user ? (
            <Dashboard
              companyName={settings["company_name"]}
              logo={`/uploads/${settings["logo"]}`}
              onLogout={logout}
              user={user.user}
            />
          ) : (
            <Login
              companyName={settings["company_name"]}
              logo={`/uploads/${settings["logo"]}`}
              onSubmit={login}
              showLangSelect={showLangSelect}
            />
          )}
        </ThemeProvider>
      </StylesProvider>
    </TranslateContext.Provider>
  );
}
