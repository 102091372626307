import React, { useState, useEffect, useMemo } from "react";
import PageCard from "commons/components/PageCard";
import {
  Box,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  Zoom,
} from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import { Save, ViewList } from "@material-ui/icons";
import { Link } from "react-router-dom";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { eqProps, update } from "ramda";
import FormSelectField from "commons/components/FormSelectField";
import LinkTabs from "commons/components/LinkTabs";
import CardSection from "commons/components/CardSection";
import Stack from "commons/components/Stack";
import api from "commons/helpers/api";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import FormTextField from "commons/components/FormTextField";
import ErrorAlert from "commons/components/ErrorAlert";
import LoadingIndicator from "commons/components/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export default function List() {
  const { t } = useTranslate();
  const [language, setLanguage] = useState(null);
  const [languages] = useResourcesByQuery("languages", true);

  return (
    <PageCard>
      <Stack>
        <Toolbar className="resource-toolbar" disableGutters>
          <Typography style={{ flex: "1 1 100%" }} variant="h4">
            {t("translations")}
          </Typography>
          <IconButton component={Link} to={`/s/languages`}>
            <ViewList />
          </IconButton>
        </Toolbar>
        <Grid container>
          <FormSelectField
            grid={4}
            label="language"
            options={languages}
            value={language}
            onChange={setLanguage}
          />
        </Grid>
        {language !== null && (
          <LinkTabs
            show={language !== null}
            tabs={[
              {
                name: "labels",
                path: `/s/translations`,
                component: (
                  <TranslationEditor
                    type="labels"
                    lang={language}
                    items={LABELS}
                  />
                ),
              },
              {
                name: "products",
                path: `/s/translations/products`,
                component: (
                  <ServiceTranslationEditor type="products" lang={language} />
                ),
              },
              {
                name: "discounts",
                path: `/s/translations/discounts`,
                component: (
                  <ServiceTranslationEditor type="discounts" lang={language} />
                ),
              },
              {
                name: "taxes",
                path: `/s/translations/taxes`,
                component: (
                  <ServiceTranslationEditor type="taxes" lang={language} />
                ),
              },
            ]}
          />
        )}
      </Stack>
    </PageCard>
  );
}

const LABELS = [
  { id: 1, name: "reference_number" },
  { id: 2, name: "line" },
  { id: 3, name: "quantity" },
  { id: 4, name: "price" },
  { id: 5, name: "count" },
  { id: 6, name: "subtotal" },
  { id: 7, name: "total" },
  { id: 8, name: "discounts" },
  { id: 9, name: "taxes" },
  { id: 10, name: "paid" },
  { id: 11, name: "remaining" },
  { id: 12, name: "code" },
  { id: 13, name: "account" },
  { id: 14, name: "currency" },
  { id: 15, name: "rate" },
  { id: 16, name: "debit" },
  { id: 17, name: "credit" },
  { id: 18, name: "statement" },
  { id: 19, name: "notes" },
  { id: 20, name: "date" },
  { id: 21, name: "serial" },
  { id: 22, name: "mfg_date" },
  { id: 23, name: "exp_date" },
  { id: 24, name: "customer" },
];

function ServiceTranslationEditor({ type, lang }) {
  const [items] = useResourcesByQuery(type, true);
  return <TranslationEditor type={type} lang={lang} items={items} />;
}

function TranslationEditor({ type, lang, items = [] }) {
  const { t, language } = useTranslate();
  const [translations, send] = useResourcesByQuery("translations");
  const [changes, setChanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (type && lang) {
      send("SET_QUERY", {
        query: {
          translatable_type: type,
          language_id: lang,
        },
      });
    }
  }, [send, type, lang]);

  const records = useMemo(() => {
    return items.map((item) => {
      const translation = translations.find(
        (t) => t.translatable_id === item.id
      );
      const change = changes.find((t) => t.translatable_id === item.id);
      return {
        id: translation ? translation.id : null,
        translatable_type: type,
        translatable_id: item.id,
        translation: change?.translation || translation?.translation || "",
        language_id: lang,
        name: item.name,
      };
    });
  }, [items, translations, changes, type, lang]);

  const noChanges = changes.length === 0;

  const onItemChange = (record) => {
    // console.log(record);
    const index = changes.findIndex(eqProps("translatable_id", record));
    if (index > -1) {
      setChanges((old) => update(index, record, old));
    } else {
      setChanges((old) => [...old, record]);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    setError(false);
    const updateRecords = changes.filter((rec) => rec.id !== null);
    const createRecords = changes.filter(
      (rec) => rec.id === null && rec.translation.trim() !== ""
    );
    const creates = createRecords.map((record) => {
      return api.service("translations").create(record);
    });
    const updates = updateRecords.map((record) => {
      return api.service("translations").update(record.id, record);
    });

    Promise.all([...updates, ...creates])
      .then(() => {
        setChanges([]);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <LoadingIndicator show={loading} />
      <ErrorAlert error={error} />
      {/* <JSONInspector data={translations} /> */}
      <CardSection p={0}>
        <Grid
          container
          spacing={2}
          alignContent="center"
          justify="center"
          alignItems="center"
        >
          <Grid item xs sm={6}>
            <Typography align="center" variant="subtitle1">
              {t("name")}
            </Typography>
          </Grid>
          <Grid item xs sm={6}>
            <Typography align="center" variant="subtitle1">
              {t("translation")}
            </Typography>
          </Grid>
        </Grid>
      </CardSection>
      <Box mt={1} minHeight="50vh">
        <AutoSizer disableWidth>
          {({ height }) => (
            <FixedSizeList
              direction={language === "ar" ? "rtl" : "ltr"}
              height={height}
              itemCount={items.length}
              itemSize={50}
              width="100%"
              overscanCount={5}
              itemData={{
                records,
                onItemChange,
                type,
              }}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
      <Box position="fixed" bottom={24} right={24}>
        <Zoom in style={{ transitionDelay: "500ms" }}>
          <Fab
            color="primary"
            onClick={onSubmit}
            disabled={noChanges && !loading}
          >
            <Save />
          </Fab>
        </Zoom>
      </Box>
    </div>
  );
}

function Row({ data, index, style }) {
  const { records, onItemChange, type } = data;
  const record = records[index];
  const classes = useStyles();
  const { t } = useTranslate();

  return (
    <div style={style} className={index % 2 ? classes.strip : ""}>
      <Grid container spacing={2} key={record.id}>
        <FormTextField
          grid={6}
          size="small"
          value={type === "labels" ? t(record.name) : record.name}
          disabled
        />
        <FormTextField
          grid={6}
          size="small"
          value={record.translation}
          onChange={(val) => onItemChange({ ...record, translation: val })}
        />
      </Grid>
    </div>
  );
}
