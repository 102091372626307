import React, { useMemo } from "react";
import ReportManager, {
  linesColumns,
  linesTransformer,
} from "commons/components/ReportManager";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { compose } from "ramda";

const filters = [
  { type: "filter", name: "categories", key: "products.category_id" },
  { type: "filter", name: "products", key: "product_id" },
  { type: "filter", name: "facilities", key: "purchases.facility_id" },
  {
    type: "filter",
    name: "users",
    key: "purchases.user_id",
    extras: { optionLabel: "username" },
  },
  { type: "filter", name: "suppliers", key: "purchases.supplier_id" },
];

const columns = [
  {
    name: "category",
    type: "text",
  },
  ...linesColumns,
];

const byCategoryID = (record) => record.category_id + "";

const addCategoryNames = (categories) => (records) =>
  records.map((rec) => ({ ...rec, category: categories[rec.category_id] }));

export default function PurchasesByCategory() {
  const [categories] = useResourcesByQuery("categories", true);
  const categoriesByKey = useMemo(
    () =>
      categories.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {}),
    [categories]
  );

  return (
    <ReportManager
      filterByDate
      dateField="purchases.date"
      url="purchase-lines"
      title="purchasesByCategory"
      filters={filters}
      columns={columns}
      recordsFunc={compose(
        linesTransformer(byCategoryID, "category_id"),
        addCategoryNames(categoriesByKey)
      )}
      totals={["quantity", "subtotal", "discount", "tax", "total", "cost"]}
    />
  );
}
