import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import FormSelectField from "commons/components/FormSelectField";
import useQueryAllResources from "commons/hooks/useQueryAllResources";
import FormTextField from "commons/components/FormTextField";
import { DialogIframe } from "commons/pages/sales/components/FrontDeskPage";
import useTranslate from "commons/hooks/useTranslate";
import { assoc, compose, propEq } from "ramda";
import api from "commons/helpers/api";

const saveDefaultEntity = (key, field) => (obj) => {
  window.localStorage.setItem(key, obj[field]);
  return obj;
};

const updateLinesPrices = (base, products, policies) => (model) => {
  if (model.policy_id) {
    const lines = model.lines.map((line) => {
      const policy = policies.find(
        (pol) =>
          pol.policy_id === model.policy_id &&
          pol.product_id === line.product_id
      );
      if (policy) {
        return { ...line, price: policy.price };
      } else {
        const isSameProduct = propEq("product_id", line.product_id);
        const product = products.find(isSameProduct);
        return {
          ...line,
          price:
            product[base === "customers" ? "sale_price" : "purchase_price"],
        };
      }
    });
    return { ...model, lines };
  }
};

export default function EntityCard({
  compact = false,
  isCreating = false,
  model,
  onChange,
  base,
  rules,
  policies = [],
  products = [],
}) {
  const { t } = useTranslate();
  const [entities] = useQueryAllResources(base);
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const field = useMemo(
    () => (base === "customers" ? "customer_id" : "supplier_id"),
    [base]
  );
  const defaultKey = useMemo(
    () => (base === "customers" ? "fdDefaultCustomer" : "fdDefaultSupplier"),
    [base]
  );

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const onEntityChange = useCallback(
    (id) => {
      const entity = entities.find((rec) => rec.id === id);
      if (entity) {
        compose(
          onChange,
          updateLinesPrices(base, products, policies),
          saveDefaultEntity(defaultKey, field),
          assoc("policy_id", entity.policy_id),
          assoc(field, entity.id)
        )(model);
      }
    },
    [entities, field, model, defaultKey, onChange, base, products, policies]
  );

  const entity = useMemo(
    () => entities.find((rec) => rec.id === model[field]),

    [entities, field, model]
  );

  useEffect(() => {
    if (entity) {
      api
        .service("balance")
        .get(entity.id, { query: { type: base } })
        .then((res) => setBalance(res.balance));
    }
  }, [entity, base]);

  useEffect(() => {
    if (isCreating && !model[field] && !model.policy_id) {
      const id = parseInt(window.localStorage.getItem(defaultKey)) || null;
      const entity = entities.find((rec) => rec.id === id);
      if (entity) {
        onEntityChange(id);
      }
    }
  }, [isCreating, model, field, entities, defaultKey, onEntityChange]);

  const showLimit = useMemo(
    () => entity && entity.credit_limit !== 0,
    [entity]
  );

  useEffect(() => {
    const handler = (event) => {
      if (event.data[field]) {
        onEntityChange(event.data[field]);
      }
    };
    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }, [onEntityChange, field]);

  return (
    <Grid container spacing={2}>
      <FormSelectField
        grid={3}
        label="code"
        options={entities}
        value={model[field]}
        onChange={onEntityChange}
        optionLabel="id"
        error={rules[field]}
        autoHighlight
      />
      <FormSelectField
        grid={model[field] ? 7 : 9}
        label={field}
        options={entities}
        value={model[field]}
        onChange={onEntityChange}
        error={rules[field]}
        autoHighlight
      />
      {model[field] && (
        <Grid item sm={2}>
          <IconButton onClick={handleDialogOpen}>
            <AccountCircle />
          </IconButton>
        </Grid>
      )}
      {!compact && (
        <>
          <FormTextField
            grid={4}
            label="policy"
            value={entity?.policy}
            disabled
          />
          <FormTextField
            grid={4}
            label="credit_limit"
            value={showLimit ? entity.credit_limit + balance : 0}
            disabled
          />
          <FormTextField
            grid={4}
            label="balance"
            value={showLimit ? balance : 0}
            disabled
          />
        </>
      )}
      <Dialog onClose={handleDialogClose} open={open} maxWidth="xl" fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
          {t(base)}
        </DialogTitle>
        <DialogContent dividers>
          <DialogIframe base={base} path={model[field]} />
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
