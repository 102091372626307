import React, { useCallback, useEffect, useMemo, useState } from "react";
import useQueryAllResources from "commons/hooks/useQueryAllResources";
import useTranslate from "commons/hooks/useTranslate";
import { sumField } from "commons/helpers/utils";
import CardSection from "commons/components/CardSection";
import Stack from "commons/components/Stack";
import { TotalsLine } from "./TotalsCard";
import { Button, ButtonGroup, Divider, Grid } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import FormSelectField from "commons/components/FormSelectField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import { FormDateTimeField } from "commons/components/FormDateField";
import FormTextField from "commons/components/FormTextField";
import { assoc, compose, __ } from "ramda";
import dayjs from "dayjs";

export default function PaymentCard({ model, onPayment }) {
  const { t } = useTranslate();
  const [currencies] = useQueryAllResources("currencies");
  const [payment, setPayment] = useState({});
  const [expanded, setExpanded] = useState(false);

  const rate = useMemo(() => payment.rate / 100 || 1, [payment.rate]);
  const paid = useMemo(
    () => sumField("amount")(model.payments),
    [model.payments]
  );
  const remaining = useMemo(() => model.total - paid, [model.total, paid]);
  const difference = useMemo(
    () => payment.amount_in_currency - Math.round(remaining / rate),
    [payment.amount_in_currency, remaining, rate]
  );

  const updatePayment = useCallback(
    (p) => {
      setPayment({
        ...p,
        amount: Math.round(p.amount_in_currency * rate) || 0,
      });
    },
    [setPayment, rate]
  );

  const onChangeCurrency = useCallback(
    (id) => {
      const currency = currencies.find((cur) => cur.id === id);
      if (currency) {
        compose(
          updatePayment,
          assoc("currency_id", id),
          assoc("rate", currency.rate)
        )(payment);
        window.localStorage.setItem("default_currency", JSON.stringify(id));
      }
    },
    [currencies, payment, updatePayment]
  );
  const updateField = (field) =>
    compose(updatePayment, assoc(field, __, payment));

  const onChangeAmountInCurrency = updateField("amount_in_currency");
  const onChangeMethod = updateField("method");
  const onChangeDate = updateField("date");
  const onChangeReference = updateField("reference");

  const preparePayment = (obj = {}) => {
    const p = {
      ...payment,
      date: payment.date || dayjs(),
      method: payment.method || "CASH",
      ...obj,
    };
    onPayment(p);
    setPayment({});
  };

  const onCollect = () => preparePayment();
  const onPay = () => {
    const toBePaid = Math.min(
      Math.round(remaining / rate),
      payment.amount_in_currency
    );
    preparePayment({
      amount_in_currency: toBePaid,
      amount: Math.round(toBePaid * rate),
    });
  };

  const onRefund = () => {
    const toBePaid = Math.min(
      Math.round(Math.abs(remaining) / rate),
      Math.abs(payment.amount_in_currency)
    );
    preparePayment({
      amount_in_currency: toBePaid * -1,
      amount: Math.round(toBePaid * rate) * -1,
    });
  };

  const disabled =
    !Boolean(payment.rate) ||
    !Boolean(payment.amount_in_currency) ||
    payment.amount_in_currency === 0;

  useEffect(() => {
    if (!payment.currency_id) {
      onChangeCurrency(
        JSON.parse(window.localStorage.getItem("default_currency"))
      );
    }
  }, [payment.currency_id, onChangeCurrency]);

  useEffect(() => {
    if (remaining !== 0) {
      const amount_in_currency = Math.round(remaining / rate);
      setPayment((old) => ({
        ...old,
        amount_in_currency,
        amount: Math.round(amount_in_currency * rate) || 0,
      }));
    }
  }, [rate, remaining]);

  return (
    <>
      <CardSection p={1}>
        <TotalsLine title={t("paid")} value={paid / 100} />
        <Divider />
        <TotalsLine title={t("remaining")} value={remaining / 100} />
      </CardSection>
      <CardSection p={1}>
        <Button fullWidth onClick={() => setExpanded(!expanded)}>
          {expanded ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </Button>
        <Stack>
          <Grid container spacing={1}>
            {expanded && (
              <>
                <FormDateTimeField
                  grid={12}
                  label="date"
                  value={payment.date}
                  onChange={onChangeDate}
                />
                <FormSelectField
                  grid={6}
                  label="currency"
                  options={currencies}
                  optionLabel="code"
                  value={payment.currency_id}
                  onChange={onChangeCurrency}
                />
                <FormMoneyField
                  grid={6}
                  label="rate"
                  value={payment.rate}
                  disabled
                />
                <FormSelectField
                  grid={6}
                  label="payment_method"
                  options={[
                    "CASH",
                    "CREDIT_CARD",
                    "WIRE_TRANSFER",
                    "CHEQUE",
                  ].map((i) => ({
                    id: i,
                    name: t(i),
                  }))}
                  value={payment.method}
                  onChange={onChangeMethod}
                />
                <FormTextField
                  grid={6}
                  label="reference"
                  value={payment.reference}
                  onChange={onChangeReference}
                />
              </>
            )}
            <FormMoneyField
              grid={6}
              label="tendered"
              value={Math.abs(payment.amount_in_currency)}
              onChange={onChangeAmountInCurrency}
            />
            <FormMoneyField
              grid={6}
              label="returned_amount"
              value={difference > 0 ? difference : 0}
              disabled
            />
          </Grid>
          {remaining < 0 ? (
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={onRefund}
              disabled={disabled}
              id="fd-pay-btn"
            >
              {t("refunds")}
            </Button>
          ) : (
            <ButtonGroup fullWidth>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={onPay}
                disabled={disabled}
                id="fd-pay-btn"
              >
                {t("pay")}
              </Button>
              {difference > 0 && (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={onCollect}
                  disabled={disabled}
                >
                  {t("collect")} : {payment.amount_in_currency / 100}
                </Button>
              )}
            </ButtonGroup>
          )}
        </Stack>
      </CardSection>
    </>
  );
}
