import React from "react";
import { ComponentsMap } from "../form-templates/BuilderComponents";
import { Box, Grid, TableContainer } from "@material-ui/core";
import CardSection from "commons/components/CardSection";
import ReactDataSheet from "react-datasheet";

const MyComponentsMap = {
  ...ComponentsMap,
  table: TableViewer,
};

export default function FormManager({ template, value = {}, onChange }) {
  const onItemChange = (item) => {
    onChange({ ...value, [item.key || item.id]: item.value });
  };

  const getCurrentItem = (item) => {
    if (item.type === "table") {
      return value[item.id] || [];
    } else {
      return {
        ...item,
        value:
          (item.key && value[item.key] ? value[item.key] : value[item.id]) ||
          item.value,
      };
    }
  };
  return (
    <Grid item xs={12}>
      <CardSection>
        <Grid container alignItems="flex-start" spacing={2}>
          {template.map((item, index) => {
            const CurrentComponent = MyComponentsMap[item.type];
            const currentItem = getCurrentItem(item);
            return (
              <Grid item sm={parseInt(item.size)} key={item.id}>
                <Box display="flex" alignItems="flex-start">
                  <CurrentComponent
                    item={currentItem}
                    onChange={onItemChange}
                    template={item}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </CardSection>
    </Grid>
  );
}

function TableViewer({ item, template, onChange }) {
  const rowRenderer = (props) => {
    // console.log(props);
    return (
      <tr>
        {template.showIndex && (
          <td className="cell index-cell">{props.row !== 0 && props.row}</td>
        )}
        {props.children}
      </tr>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <h3>{template.label}</h3>
      {/* <div dir="ltr"> */}
      <TableContainer>
        <ReactDataSheet
          data={template.value}
          valueRenderer={(cell, row, col) => {
            return item?.[row]?.[col] || cell.value;
          }}
          rowRenderer={rowRenderer}
          onCellsChanged={(changes) => {
            console.log(changes);
            const grid = [...item];
            changes.forEach(({ cell, row, col, value }) => {
              if (Array.isArray(grid[row])) {
                grid[row][col] = value;
              } else {
                grid[row] = [];
                grid[row][col] = value;
              }
            });
            // console.log("grid", grid);
            onChange({ id: template.id, value: grid });
          }}
        />
      </TableContainer>
      {/* </div> */}
    </div>
  );
}
