import { sumField } from "commons/helpers/utils";
import useTranslate from "commons/hooks/useTranslate";
import dayjs from "dayjs";
import { eqProps } from "ramda";
import { useState } from "react";
import { createStockLine, groupStocks } from "./utils/stocks-utils";
import { v4 as uuidv4 } from "uuid";

export function useFrontDeskStocks({
  sales = true,
  model,
  onChange,
  products,
  components,
  settings,
  canDoAction,
  productOwnStocks,
  productCompStocks,
  stockTotals,
  stockLevels,
}) {
  const { t } = useTranslate();
  const [error, setError] = useState(null);

  const updateLinesField = (lines) => (field) => (id) => (value) => {
    const newLines = lines.map((l) => {
      if (l.id === id) {
        return { ...l, [field]: value };
      } else {
        return l;
      }
    });
    onChange({ ...model, lines: newLines });
  };

  const updateLineField = updateLinesField(model.lines);

  const mergeLinesFields = (lines) => (id) => (fields) => {
    setError(null);
    const newLines = lines.map((l) => {
      if (l.id === id) {
        return { ...l, ...fields };
      } else {
        return l;
      }
    });
    onChange({ ...model, lines: newLines });
  };

  const mergeLineFields = mergeLinesFields(model.lines);

  const onChangeLineQuantity =
    (lines) =>
    (id) =>
    (value, extras = {}) => {
      const line = lines.find((l) => l.id === id);
      const product = products.find(eqProps("product_id", line));
      if (!line || !product) return;
      if (!settings["autoStockChange"]) {
        updateLinesField(lines)("quantity")(id)(value);
        return;
      }
      if (line.savedQty * 1 > value) {
        setError(t("RETURN_QUANTITY"));
        return;
      }
      const updated = sales
        ? onSaleQuantityChange(line, value, product)
        : onPurchaseQuantityChange(line, value, product);
      if (typeof updated === "string") {
        setError(t(updated));
        return;
      }
      mergeLinesFields(lines)(id)({ ...updated, ...extras });
    };

  const onChangeQuantity = onChangeLineQuantity(model.lines);

  const onChangeReturned = (id) => (value) => {
    const line = model.lines.find((l) => l.id === id);
    const product = products.find(eqProps("product_id", line));
    if (!line) return;
    if (!settings["autoStockChange"] || !product.stockable) {
      updateLineField("returned")(id)(value);
      return;
    }
    const updated = sales
      ? onSaleReturnedChange(line, value)
      : onPurchaseReturnedChange(line, value);
    if (typeof updated === "string") {
      setError(t(updated));
      return;
    }
    mergeLineFields(id)(updated);
  };

  const onPurchaseQuantityChange = (line, value, product) => {
    return {
      ...line,
      quantity: value * 1,
      returned: line.savedReturned,
      stocks: !product.stockable
        ? [...line.stocks]
        : [
            ...line.stocks.filter(
              (l) => Boolean(l.operation_line_id) // only saved lines
            ),
            createStockLine({
              product_id: line.product_id,
              value: line.price,
              quantity: value * 1 - line.savedQty,
              facility_id: model.facility_id,
              fulfilled: settings["autoFulfilStocks"] ? dayjs() : null,
            }),
          ],
    };
  };
  const onPurchaseReturnedChange = (line, value) => {
    const error = validatePurchaseReturn(line, value);
    if (error !== null) return error;
    const stocksGrouped = groupStocks(
      line.stocks.filter((l) => Boolean(l.operation_line_id))
    ).filter((g) => g.quantity > 0);
    const canReturn = sumField("quantity")(stocksGrouped);
    const change = value * 1 - line.savedReturned;
    const returned = Math.min(change, canReturn);
    const over = Math.max(change - canReturn, 0); // What is the amount I can return.
    return {
      ...line,
      quantity: line.savedQty,
      returned: value * 1,
      stocks: [
        ...line.stocks.filter(
          (l) => Boolean(l.operation_line_id) // only saved
        ),
        ...getStocksChanges(stocksGrouped, returned, -1),
        ...(over > 0
          ? [
              createStockLine({
                product_id: line.product_id,
                value: line.price,
                quantity: -1 * over,
                facility_id: model.facility_id,
                fulfilled: settings["autoFulfilStocks"] ? dayjs() : null,
              }),
            ]
          : []),
      ],
    };
  };
  const validatePurchaseReturn = (line, value) => {
    // has return permission
    if (!canDoAction("return-line")) return "NOT_AUTHORIZED_TO_RETURN";
    // amount cannot be less than already returned amount
    if (line.savedReturned * 1 > value) return "CANNOT_RETURN_RETURNED";
    // has allow more than sold permission
    if (line.quantity * 1 < value && !canDoAction("over-return-line"))
      return "CANNOT_OVER_RETURN";
    // has allow negative stocks permission
    const total = stockTotals[line.product_id] || 0;
    if (total - (value - line.returned) < 0 && !settings["allowNegativeStocks"])
      return "NEGATIVE_STOCKS_NOT_ALLOWED";
    return null;
  };

  const overMax = (product, qty) => {
    const { max_in_sale } = product;
    const over_max = Number(max_in_sale) > 0 && Number(max_in_sale) < qty;
    return over_max && !canDoAction("operation-over-limit");
  };

  const haveEnoughComponents = (components, qty) => {
    return components.every((comp) => {
      const { component_id, product_ratio, component_ratio } = comp;
      const max = productOwnStocks[component_id] || 0;
      const ratio = component_ratio / product_ratio;
      return max >= qty * ratio;
    });
  };

  const normalizeRatio = (val) => Math.round(val * 1000) / 1000;

  const updateType = (type) => (lines) =>
    lines.map((line) => ({ ...line, operation_type: type }));

  const saleComponentType = updateType("componentOut");
  const saleCompositeIn = updateType("compositeIn");
  const saleCompositeOut = updateType("compositeOut");

  const validateSale = (line, value, product) => {
    if (overMax(product, Number(value))) return "MAX_IN_SALE";
    // 1- stockable and has enough from own
    // 2- stockable and has enough from components
    // 3- stockable and has allow negative stocks
    // 4- non stockable but has enough components.
    const total = stockTotals[line.product_id] || 0;
    const change = value - line.quantity;
    const unSavedReturned = line.returned - line.savedReturned;
    const myComponents = components.filter(eqProps("product_id", product));
    if (
      (product.stockable && total - unSavedReturned - change < 0) ||
      (!product.stockable &&
        !haveEnoughComponents(myComponents, change) &&
        !settings["allowNegativeStocks"])
    )
      return "NEGATIVE_STOCKS_NOT_ALLOWED";

    return null;
  };

  const getComponentsStockChange = (components, change) => {
    return components.flatMap((comp) => {
      const { component_id, component_ratio, product_ratio } = comp;
      const levels = stockLevels[component_id] || [];
      const ratio = component_ratio / product_ratio;
      return getStocksChanges(
        levels.filter((level) => level.quantity !== 0),
        normalizeRatio(change * ratio),
        -1
      );
    });
  };

  const onSaleQuantityChange = (line, value, product) => {
    const error = validateSale(line, value, product);
    if (error !== null) return error;
    const myComponents = components.filter(eqProps("product_id", product));
    const change = value - line.savedQty;
    const unSavedChange = sumField("quantity")(
      line.stocks.filter((s) => !Boolean(s.operation_type))
    ); //line.quantity - line.savedQty;
    const ownTotal = productOwnStocks[line.product_id] || 0;
    const levels = stockLevels[line.product_id] || [];
    // Stockable and have enough stock of its own
    // Stockable and allowNegativeStocks
    if (product.stockable) {
      const canSell = ownTotal - unSavedChange;
      const moved = Math.min(change, canSell);
      const over = Math.max(change - canSell, 0);
      let extras = [];
      if (over > 0) {
        if (haveEnoughComponents(myComponents, value - line.quantity)) {
          const componentsOut = getComponentsStockChange(myComponents, over);
          const productIn = [
            createStockLine({
              product_id: line.product_id,
              value: line.price,
              quantity: over * 1,
              facility_id: model.facility_id,
              fulfilled: settings["autoFulfilStocks"] ? dayjs() : null,
            }),
          ];
          const productOut = getStocksChanges(productIn, over, -1);
          extras = [
            ...saleComponentType(componentsOut),
            ...saleCompositeIn(productIn),
            ...saleCompositeOut(productOut),
          ];
        } else {
          extras = [
            createStockLine({
              product_id: line.product_id,
              value: line.price,
              quantity: -1 * over,
              facility_id: model.facility_id,
              fulfilled: settings["autoFulfilStocks"] ? dayjs() : null,
            }),
          ];
        }
      }
      return {
        ...line,
        quantity: value * 1,
        returned: line.savedReturned,
        stocks: [
          ...line.stocks.filter((l) => Boolean(l.operation_line_id)),
          ...getStocksChanges(levels, moved, -1),
          ...extras,
        ],
      };
    }
    // Not Stockable, without components, return changed
    if (myComponents.length === 0)
      return {
        ...line,
        quantity: value * 1,
        returned: line.savedReturned,
        stocks: settings["printNoStorage"]
          ? [
              createStockLine({
                product_id: line.product_id,
                facility_id: model.facility_id,
                value: 0,
                quantity: -1 * change,
                total_value: 0,
                operation_type: "printOnly",
                fulfilled: settings["autoFulfilStocks"] ? dayjs() : null,
              }),
            ]
          : [],
      };

    // - Not stockable, with components, return taking components out
    return {
      ...line,
      quantity: value * 1,
      returned: line.savedReturned,
      stocks: getComponentsStockChange(myComponents, change),
    };
  };

  const validateSaleReturn = (line, value) => {
    // has return permission
    if (!canDoAction("return-line")) return "NOT_AUTHORIZED_TO_RETURN";
    // amount cannot be less than already returned amount
    if (line.savedReturned * 1 > value) return "CANNOT_RETURN_RETURNED";
    // has allow more than sold permission
    if (line.quantity * 1 < value && !canDoAction("over-return-line"))
      return "CANNOT_OVER_RETURN";
    // Not needed, returns in sale are positive, never going to make the stocks negative
    // // has allow negative stocks permission
    // const total = stockTotals[line.product_id] || 0;
    // if (total - (value - line.returned) < 0 && !settings["allowNegativeStocks"])
    //   return "NEGATIVE_STOCKS_NOT_ALLOWED";
    return null;
  };

  const onSaleReturnedChange = (line, value) => {
    const error = validateSaleReturn(line, value);
    if (error !== null) return error;
    const stocksGrouped = groupStocks(
      line.stocks
        .filter(eqProps("product_id", line))
        .filter((l) => Boolean(l.operation_line_id))
        .filter((rec) => rec.operation_type !== "compositeIn")
    ).filter((g) => g.quantity < 0);
    const canReturn = Math.abs(sumField("quantity")(stocksGrouped));
    const change = value * 1 - line.savedReturned;
    const returned = Math.min(change, canReturn);
    const over = Math.max(change - canReturn, 0); // What is the amount I can return.
    return {
      ...line,
      quantity: line.savedQty,
      returned: value * 1,
      stocks: [
        ...line.stocks.filter(
          (l) => Boolean(l.operation_line_id) // only saved or non-returned lines.
        ),
        ...getStocksChanges(stocksGrouped, returned, 1),
        ...(over > 0
          ? [
              createStockLine({
                product_id: line.product_id,
                value: line.price,
                quantity: 1 * over,
                facility_id: model.facility_id,
                fulfilled: settings["autoFulfilStocks"] ? dayjs() : null,
              }),
            ]
          : []),
      ],
    };
  };

  const getStocksChanges = (levels = [], qty, direction) => {
    if (qty === 0 || levels.length === 0) return [];
    const [curr, ...rest] = levels;
    const currQty = Number(curr.quantity);
    const currHasEnoughStocks = Math.abs(currQty) >= Math.abs(qty);
    const quantity = currHasEnoughStocks ? qty : currQty;
    const remaining = currHasEnoughStocks ? 0 : qty - currQty;
    return [
      {
        ...curr,
        id: uuidv4(),
        requested: dayjs(),
        fulfilled: settings["autoFulfilStocks"] ? dayjs() : null,
        quantity: quantity * direction,
        total_value: curr.value * quantity * direction,
        operation_line_id: null,
        serial: null,
      },
      ...getStocksChanges(rest, remaining, direction),
    ];
  };

  const onDeleteLine = (line) => () => {
    if (line.savedQty > 0 && !canDoAction("remove-saved-line")) {
      setError(t("CANNOT_REMOVE_LINE"));
      return;
    } else {
      onChange({
        ...model,
        lines: model.lines.filter((l) => l.id !== line.id),
      });
    }
  };

  return {
    error,
    updateLineField,
    mergeLineFields,
    onChangeLineQuantity,
    onChangeQuantity,
    onChangeReturned,
    onDeleteLine,
  };
}
