import React, { useState, useMemo, useCallback } from "react";
import ReportManager from "commons/components/ReportManager";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import { Grid } from "@material-ui/core";

const filters = [
  {
    type: "filter",
    name: "users",
    key: "user_id",
    extras: { optionLabel: "username" },
  },
  { type: "filter", name: "customers", key: "customer_id" },
];

const recordsFn = (columns) => (records) => {
  return records.map((record) => ({
    id: record.id,
    customer: record["customer"],
    user: record["user"],
    ...columns.reduce(
      (acc, col) => ({
        ...acc,
        [col.name]: record.body[col.id] ?? col.default,
      }),
      {}
    ),
  }));
};

export default function FormsReport() {
  const [templates] = useResourcesByQuery("form-templates", true);
  const [template, setTemplate] = useState(null);

  const columns = useMemo(() => {
    if (template === null) return [];
    return template.body.reduce((acc, curr) => {
      if (
        curr.type === "textfield" ||
        curr.type === "textarea" ||
        curr.type === "singlechoose"
      ) {
        console.log(curr);
        return [
          ...acc,
          {
            id: curr.id,
            label: curr.label || "-",
            name: curr.label + curr.id,
            type: "text",
            default: curr.value,
          },
        ];
      }
      if (curr.type === "datefield") {
        return [
          ...acc,
          {
            id: curr.id,
            label: curr.label || "-",
            name: curr.label + curr.id,
            type: "date",
            default: curr.value,
          },
        ];
      }
      if (curr.type === "multichoose") {
        return [
          ...acc,
          {
            id: curr.id,
            label: curr.label || "-",
            name: curr.label + curr.id,
            type: "multi",
            default: curr.value,
          },
        ];
      }
      return acc;
    }, []);
  }, [template]);

  const dataModifier = useCallback(recordsFn(columns), [columns]);

  const allColumns = useMemo(
    () => [
      { id: "customer", name: "customer", type: "text" },
      { id: "user", name: "user", type: "text" },
      ...columns,
    ],
    [columns]
  );

  return (
    <ReportManager
      url="forms"
      title="formsReport"
      columns={allColumns}
      recordsFunc={dataModifier}
      filterByDate
      dateField="created"
      filters={filters}
    >
      <Grid container>
        <FormSelectField
          label="template"
          options={templates}
          value={template ? template.id : null}
          onChange={(val) => {
            const temp = templates.find((template) => template.id === val);
            if (temp) {
              setTemplate(temp);
            }
          }}
        />
      </Grid>
    </ReportManager>
  );
}
