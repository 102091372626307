import React from "react";
import ReportManager from "commons/components/ReportManager";

const filters = [
  {
    type: "filter",
    name: "contractors",
    key: "employee_id",
  },
];

const columns = [
  {
    name: "name",
    type: "text",
  },
  {
    name: "sales_total",
    type: "money",
    highlight: true,
  },
  {
    name: "purchases_total",

    type: "number",
  },
  {
    name: "payments_total",
    type: "money",
    highlight: true,
  },
  {
    name: "balance",
    type: "balance",
    highlight: true,
  },
];

export default function ContractorsReport() {
  return (
    <ReportManager
      url="balance"
      title="contractorsReport"
      columns={columns}
      filters={filters}
      totals={["sales_total", "purchases_total", "payments_total", "balance"]}
      filterByDate
      initialQuery={{ type: "contractors" }}
    />
  );
}
