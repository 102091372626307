import React, { useEffect, useMemo, useState } from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import useTranslate from "commons/hooks/useTranslate";
import PageCard from "commons/components/PageCard";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";
import Stack from "commons/components/Stack";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
  IconButton,
  Grid,
} from "@material-ui/core";
import {
  DurationFilter,
  ReportsToolbar,
  FilterField,
} from "commons/components/ReportManager";
import { sumField } from "commons/helpers/utils";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import { MyTableCell, viewCol } from "./AccountsBalance";
import dayjs from "dayjs";
import CardSection from "commons/components/CardSection";
import {
  PrintTemplate,
  usePrintManager,
} from "commons/components/PrintManager";

const columns = [
  {
    name: "currency",
    type: "text",
  },
  {
    name: "debit",
    type: "money",
  },
  {
    name: "credit",
    type: "money",
  },
  {
    name: "difference",
    type: "money",
  },
];

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export default function AccountsBalance() {
  const { t } = useTranslate();
  const classes = useStyles();
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();
  const [accounts, setAccounts] = useState([]);
  const [currencies] = useResourcesByQuery("currencies", true);
  const [duration, setDuration] = useState({
    start: dayjs().startOf("YEAR"),
    end: dayjs().endOf("YEAR"),
  });
  const [records, send, error, isLoading] = useResourcesByQuery(
    "accounts-balance",
    false
  );

  useEffect(() => {
    send("SET_QUERY", {
      query: {
        created: {
          $gte: duration.start,
          $lte: duration.end,
        },
      },
    });
  }, [send, duration]);

  const currenciesBalance = useMemo(() => {
    return currencies.map((currency) => {
      const currency_records = records.filter(
        (rec) =>
          rec.currency_id === currency.id &&
          (accounts.length === 0 || accounts.includes(rec.account_id))
      );
      const debit_total = sumField("debit_in_currency")(currency_records);
      const credit_total = sumField("credit_in_currency")(currency_records);
      return {
        ...currency,
        debit_total,
        credit_total,
        difference: debit_total - credit_total,
      };
    });
  }, [accounts, currencies, records]);

  return (
    <PrintTemplate active={active} onPrintCompleted={onPrintCompleted}>
      <PageCard>
        <LoadingIndicator show={isLoading} />
        <ErrorAlert error={error} />
        <Stack>
          <ReportsToolbar
            title="currenciesBalance"
            columns={columns}
            records={records}
            onPrintRequest={onPrintRequest}
          />
          <DurationFilter
            duration={duration}
            onChange={setDuration}
            initialType="YEAR"
          />
          <CardSection>
            <Grid container spacing={2}>
              <FilterField
                grid={4}
                source="accounts"
                name="accounts"
                label={t("accounts")}
                value={accounts}
                onChange={setAccounts}
              />
            </Grid>
          </CardSection>
          <TableContainer>
            <Table>
              <TableHead className={classes.highlight}>
                <TableRow>
                  {columns.map((col) => (
                    <TableCell key={col.name}>
                      {t(col.label || col.name)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currenciesBalance.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1}>
                      {t("noResources")}
                    </TableCell>
                  </TableRow>
                )}
                {currenciesBalance.map((currency) => (
                  <TableRow hover key={currency.id}>
                    <MyTableCell
                      value={" (" + currency.code + ") " + currency.name}
                    />
                    <MyTableCell
                      value={viewCol(currency.debit_total, "money")}
                    />
                    <MyTableCell
                      value={viewCol(currency.credit_total, "money")}
                    />
                    <MyTableCell
                      value={viewCol(currency.difference, "difference")}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </PageCard>
    </PrintTemplate>
  );
}

function MyTableRowGroup({ parent, accounts, depth = 1 }) {
  const myChildren = accounts.filter((acc) => acc.parent_account_id === parent);

  return myChildren.map((acc) => (
    <MyTableRow key={acc.id} acc={acc} accounts={accounts} depth={depth} />
  ));
}

function MyTableRow({ acc, accounts, depth }) {
  const [show, setShow] = useState(false);
  const myChildren = accounts.filter(
    (curr) => curr.parent_account_id === acc.id
  );

  return (
    <React.Fragment>
      <TableRow hover key={acc.id}>
        <TableCell width={40}>
          {myChildren.length > 0 && (
            <IconButton size="small" onClick={() => setShow(!show)}>
              {show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <MyTableCell
          value={Array(depth).join("----") + " (" + acc.code + ") " + acc.name}
        />
        <MyTableCell value={viewCol(acc.debit_total, "money")} />
        <MyTableCell value={viewCol(acc.credit_total, "money")} />
        <MyTableCell value={viewCol(acc.difference, "difference")} />
      </TableRow>
      {show && (
        <MyTableRowGroup
          parent={acc.id}
          accounts={accounts}
          depth={depth + 1}
        />
      )}
    </React.Fragment>
  );
}
