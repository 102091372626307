import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import useTranslate from "commons/hooks/useTranslate";
import { prop, trim, zipObj } from "ramda";
import api from "commons/helpers/api";
import LoadingIndicator from "./LoadingIndicator";

export function Importer({
  base,
  title = base,
  columns = [],
  beforeSave = (records) => records,
}) {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const [raw, setRaw] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(-1);
  const [total, setTotal] = useState(0);

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const zipObjColumns = zipObj(columns);

  const commitData = () => {
    const lines = raw.split("\n").filter(Boolean);
    const records = lines.map((line) => line.split(",").map(trim));
    const named = records.map(zipObjColumns);
    setData(named);
  };

  const reset = () => {
    setRaw("");
    setData([]);
    setLoading(false);
    setErrors([]);
    setSuccess(-1);
    setTotal(0);
  };

  const save = async () => {
    setLoading(true);
    const model = beforeSave(data);
    const reqs = model.map((i) => api.service(base).create(i));
    setTotal(model.length);
    Promise.allSettled(reqs).then((results) => {
      const errors = results.filter((r) => r.status === "rejected");
      const success = results.filter((r) => r.status === "fulfilled");
      setSuccess(success.length);
      setErrors(errors.map(prop("reason")));
      setLoading(false);
    });
  };

  return (
    <div>
      <LoadingIndicator show={loading} />
      <Tooltip title={t("import")}>
        <IconButton onClick={handleDialogOpen}>
          <CloudUpload />
        </IconButton>
      </Tooltip>
      <Dialog onClose={handleDialogClose} open={open} maxWidth="xl" fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
          {`${t("import")} ${t(title)}`}
        </DialogTitle>
        <DialogContent dividers>
          <Box p={2} textAlign="center">
            {data.length > 0 ? (
              <>
                <ImporterTable data={data} columns={columns} />
                <br />
                <br />
                {success < 0 ? (
                  <Button onClick={save} variant="contained" color="primary">
                    {t("save")}
                  </Button>
                ) : (
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("total")}</TableCell>
                          <TableCell>{t("success")}</TableCell>
                          <TableCell>{t("errors")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{total}</TableCell>
                          <TableCell>{success}</TableCell>
                          <TableCell>{errors.length}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <br />
                    <br />
                    <Button onClick={reset} variant="contained" color="primary">
                      {t("back")}
                    </Button>
                  </div>
                )}
                {errors.length > 0 && (
                  <Box bgcolor="error.main">
                    <ul>
                      {errors.map((err, i) => (
                        <li key={i}>{err.message}</li>
                      ))}
                    </ul>
                  </Box>
                )}
              </>
            ) : (
              <>
                <p>
                  <strong>{columns.map(t).join(", ")}</strong>
                </p>
                <TextField
                  label={t("data")}
                  value={raw}
                  onChange={(e) => setRaw(e.target.value)}
                  multiline
                  rows={6}
                  rowsMax={20}
                />
                <br />
                <br />
                <Button
                  onClick={commitData}
                  variant="contained"
                  color="primary"
                >
                  {t("next")}
                </Button>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

function ImporterTable({ data = [], columns = [] }) {
  const classes = useStyles();
  const { t } = useTranslate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead className={classes.highlight}>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell key={index}>{t(col)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  {t("noResources")}
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 &&
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    className={index % 2 ? classes.strip : ""}
                    hover
                    key={index}
                  >
                    {columns.map((col) => (
                      <TableCell key={col}>
                        <Box display="block" maxWidth={200}>
                          <Typography variant="body1">{row[col]}</Typography>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" className={classes.highlight}>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
}
