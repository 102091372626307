import React, { useMemo, useState } from "react";
import useTranslate from "commons/hooks/useTranslate";
import PageCard from "commons/components/PageCard";
import { Typography, Grid, Box, Zoom, Fab } from "@material-ui/core";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import Stack from "commons/components/Stack";
import FormFileField from "commons/components/FormFileField";
import FormTextField from "commons/components/FormTextField";
import FormSwitch from "commons/components/FormSwitch";
import ColorPicker from "material-ui-color-picker";
import { Save } from "@material-ui/icons";
import api from "commons/helpers/api";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";

export default function BaseSettings() {
  const { t } = useTranslate();
  const [savedSettings] = useResourcesByQuery("settings", true);
  const [changes, setChanges] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const settings = useMemo(() => {
    const saved = savedSettings.reduce(
      (acc, setting) => ({
        ...acc,
        [setting.key]: setting.value,
      }),
      {}
    );
    return { ...saved, ...changes };
  }, [savedSettings, changes]);

  const onChange = (field) => (value) => {
    setChanges((old) => ({ ...old, [field]: value }));
  };

  const onSubmit = () => {
    setLoading(true);
    const ops = Object.keys(changes).map((key) =>
      api.service("settings").patch(
        null,
        {
          key: key,
          value: changes[key],
        },
        {
          query: {
            key: key,
          },
        }
      )
    );
    Promise.all(ops)
      .then(() => {
        setChanges({});
        window.location.reload();
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  };

  const isBool = (val) => val === true || val === "true";

  return (
    <PageCard>
      <Stack>
        <LoadingIndicator show={loading} />
        <ErrorAlert error={error} />
        <Typography variant="h4">{t("settings")}</Typography>
        <Typography variant="h6">{t("brand")}</Typography>
        <Grid container spacing={2}>
          <Grid item xs sm={4}>
            <FormFileField
              grid={4}
              label="logo"
              value={settings.logo}
              onChange={onChange("logo")}
              // error={rules.avatar}
              preview
            />
          </Grid>
          <FormTextField
            grid={8}
            label="company_name"
            value={settings.company_name}
            onChange={onChange("company_name")}
            // error={rules.username}
          />
        </Grid>

        <Typography variant="h6">{t("storage")}</Typography>
        <Grid container spacing={2}>
          <FormSwitch
            grid={4}
            label="autoStockChange"
            value={isBool(settings.autoStockChange)}
            onChange={onChange("autoStockChange")}
          />
          <FormSwitch
            grid={4}
            label="autoFulfilStocks"
            value={isBool(settings.autoFulfilStocks)}
            onChange={onChange("autoFulfilStocks")}
          />
          <FormSwitch
            grid={4}
            label="allowNegativeStocks"
            value={isBool(settings.allowNegativeStocks)}
            onChange={onChange("allowNegativeStocks")}
          />
          <FormSwitch
            grid={4}
            label="autoFulfilOnPrint"
            value={isBool(settings.autoFulfilOnPrint)}
            onChange={onChange("autoFulfilOnPrint")}
          />
          <FormSwitch
            grid={4}
            label="printNoStorage"
            value={isBool(settings.printNoStorage)}
            onChange={onChange("printNoStorage")}
          />
          <FormSwitch
            grid={4}
            label="printComponents"
            value={isBool(settings.printComponents)}
            onChange={onChange("printComponents")}
          />
          {/* <FormSwitch
            grid={4}
            label="autoPrintOnPayment"
            value={isBool(settings.autoPrintOnPayment)}
            onChange={onChange("autoPrintOnPayment")}
          /> */}
        </Grid>

        <Typography variant="h6">{t("theme")}</Typography>
        <Grid container spacing={2}>
          <Grid item xs sm={3}>
            <ColorPicker
              label={t("theme_main_color")}
              value={settings.theme_main_color}
              onChange={onChange("theme_main_color")}
            />
          </Grid>
          <Grid item xs sm={3}>
            <ColorPicker
              label={t("theme_secondary_color")}
              value={settings.theme_secondary_color}
              onChange={onChange("theme_secondary_color")}
            />
          </Grid>
          <Grid item xs sm={3}>
            <ColorPicker
              label={t("theme_background_color")}
              value={settings.theme_background_color}
              onChange={onChange("theme_background_color")}
            />
          </Grid>
          <Grid item xs sm={3}>
            <ColorPicker
              label={t("theme_surface_color")}
              value={settings.theme_surface_color}
              onChange={onChange("theme_surface_color")}
            />
          </Grid>
        </Grid>
        <Box position="fixed" bottom={24} right={24}>
          <Zoom in style={{ transitionDelay: "500ms" }}>
            <Fab
              type="submit"
              color="primary"
              onClick={onSubmit}
              disabled={Object.keys(changes).length === 0}
            >
              <Save />
            </Fab>
          </Zoom>
        </Box>
      </Stack>
    </PageCard>
  );
}
