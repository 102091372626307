import React, { useMemo } from "react";
import {
  ReportViewer,
  useReportQuery,
  useReportController,
} from "commons/components/ReportManager";
import { sumField } from "commons/helpers/utils";

const filters = [
  {
    type: "filter",
    name: "access-groups",
    key: "user_group_permission.access_group_id",
  },
];

const columns = [
  {
    name: "line",
    type: "translate",
  },
  {
    name: "amount",
    type: "balance",
  },
];

export default function FinanceOverview() {
  const [
    serverQuery,
    query,
    duration,
    setQuery,
    setDuration,
  ] = useReportQuery();
  const [sales, salesError, salesIsLoading, salesApply] = useReportController(
    "sales",
    serverQuery,
    duration,
    "date"
  );
  const [
    stocks,
    stocksError,
    stocksIsLoading,
    stocksApply,
  ] = useReportController("stocks", serverQuery, duration, "fulfilled");
  const [
    purchases,
    purchasesError,
    purchasesIsLoading,
    purchasesApply,
  ] = useReportController("purchases", serverQuery, duration, "date");
  const [
    salaries,
    salariesError,
    salariesIsLoading,
    salariesApply,
  ] = useReportController("salaries", serverQuery, duration, "due_date");
  const [
    commissions,
    commissionsError,
    commissionsIsLoading,
    commissionsApply,
  ] = useReportController("employee-sale", serverQuery, duration, "date");
  const [
    customers,
    customersError,
    customersIsLoading,
    customersApply,
  ] = useReportController("customer-payments", serverQuery, duration, "date");
  const [
    suppliers,
    suppliersError,
    suppliersIsLoading,
    suppliersApply,
  ] = useReportController("supplier-payments", serverQuery, duration, "date");
  const [
    employees,
    employeesError,
    employeesIsLoading,
    employeesApply,
  ] = useReportController("employee-payments", serverQuery, duration, "date");

  const apply = () => {
    salesApply();
    stocksApply();
    purchasesApply();
    salariesApply();
    commissionsApply();
    customersApply();
    suppliersApply();
    employeesApply();
  };

  const records = useMemo(() => {
    const salesTotal = sumField("total")(sales);
    const stocksTotal = sumField("total_value")(stocks);
    const purchasesTotal = sumField("total")(purchases);
    const salariesTotal = sumField("net_salary")(salaries);
    const commissionsTotal = sumField("applied_value")(commissions);

    const customerPayments = sumField("amount")(customers);
    const supplierPayments = sumField("amount")(suppliers);
    const employeePayments = sumField("amount")(employees);
    return [
      { line: "sales_total", amount: salesTotal },
      { line: "stocks_total", amount: stocksTotal },
      { line: "purchases_total", amount: purchasesTotal },
      { line: "salaries_total", amount: salariesTotal },
      { line: "commissions_total", amount: commissionsTotal },
      {
        line: "profit",
        amount:
          salesTotal +
          stocksTotal -
          purchasesTotal +
          salariesTotal -
          commissionsTotal,
      },
      { line: "customer_payments", amount: customerPayments },
      { line: "supplier_payments", amount: supplierPayments },
      { line: "employee_payments", amount: employeePayments },
      {
        line: "payments_difference",
        amount: customerPayments - supplierPayments - employeePayments,
      },
    ];
  }, [
    sales,
    stocks,
    purchases,
    salaries,
    commissions,
    customers,
    suppliers,
    employees,
  ]);

  const errors = [
    salesError,
    stocksError,
    purchasesError,
    salariesError,
    commissionsError,
    customersError,
    suppliersError,
    employeesError,
  ].filter((er) => Boolean(er));
  const isLoading = [
    salesIsLoading,
    stocksIsLoading,
    purchasesIsLoading,
    salariesIsLoading,
    commissionsIsLoading,
    customersIsLoading,
    suppliersIsLoading,
    employeesIsLoading,
  ].every((l) => l);

  return (
    <ReportViewer
      title="financeOverview"
      filterByDate
      filters={filters}
      columns={columns}
      records={records}
      error={errors.length > 0 ? errors[0] : null}
      isLoading={isLoading}
      duration={duration}
      query={query}
      setQuery={setQuery}
      setDuration={setDuration}
      hideIndex
      onApply={apply}
    />
  );
}
